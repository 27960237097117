import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import image1 from "../../assets/images/newsletterimage.png"; 
import backgroundImage from '../../assets/images/background.png';
import SharedFunctions from "../classes/sharedfunctions";
const shared = new SharedFunctions();
/**
 * GPS Member Partner Newsletter Component. - This section holds image and link to the GPS Monthly Newsletter.
 */
const PartnerNewsletter = (props) => {
  let newslink = props.newsletter && props.newsletter[0] ? props.newsletter[0].newslink : '';
  let newsimg = props.newsletter && props.newsletter[0] ? props.newsletter[0].newsimage : '';
  return (
    <div
      id="partnerNewsContainer"
      className="bg-neutral flex min-h-[550px] justify-center"
    >
      <div id="partnerNewsDivder" className="flex w-5/6">
        <div id="leftSide" className="flex w-1/2 justify-end">
          <div
            id="rightSide"
            className="flex justify-center flex-col items-start w-5/6"
          >
            <h1
              id="header_text_partner"
              className="font-bold text-6xl text-left"
            >
              Partner Newsletter
            </h1>
            <p id="textCont" className="my-[25px] text-left pe-48">
              With every edition, we delve into pivotal topics, including partner and team highlights, success stories, upcoming events, new initiatives, and organizational updates.
            </p>
            <button
              className="bg-transparent flex flex-row justify-around items-center w-1/3 border-1 rounded-xl p-2 font-bold"
              id="connectButton"
              onClick={() =>
                shared.openInNewTab(
                  newslink
                )
              }
              style={{
                borderColor: "#919EAB",
              }}
            >
              Check out our latest newsletter <MdOutlineKeyboardArrowRight size={30} />
            </button>
          </div>
        </div>
        <div id="realRightSide" className="w-1/2 relative">
          <div className="w-full flex justify-center items-center h-full">
            <img id="coverImage" className="w-5/6" src={newsimg} alt="" />
          </div>
          <img id="backgrounImageNews" className="h-full w-5/6 z-10 absolute top-0 right-[-150px]" src={backgroundImage} alt=""/>
        </div>
      </div>
    </div>
  );
};

export default PartnerNewsletter;
