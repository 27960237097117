import { io } from "socket.io-client";

/**
 * GPS Member Socket IO.
 */
const URL = process.env.NODE_ENV === "production" ? "http://gps-websocket-1133658398.us-east-1.elb.amazonaws.com:4000" : "localhost:4000";

const socket = io(URL, {
	reconnectionDelayMax: 10000,
	autoConnect: false 
});
//console.log(URL);

export default socket;