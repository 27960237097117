import React from "react";
//import variables from "./rests/variables";
//import { isExpired, decodeToken } from "react-jwt";
import Welcome from "./Welcome";
import OutreachEfforts from "./Outreach";
//import QuarterlyGifts from "../quarterlygifts/QuarterlyGifts";
import YourImpact from "./Impact";
import UpcomingEvents from "./UpcomingEvents";
import PhotoGallery from "./PhotoGallery";
import FAQs from "./FAQs";
import GPSTeam from "./GPSTeam";
import Connect from "./Connect";
import PartnerNewsletter from "./PartnerNewsletter";
/**
 * GPS Member Landing Page Component. - This one page shows the different sections of the member portal.
 * 
 */
class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showprogress: false};
    }

    render() {
        return (
            <>
                <Welcome {...this.props}/>
                <OutreachEfforts {...this.props}/>
                <YourImpact {...this.props}/>
                <PartnerNewsletter {...this.props}/>
                <UpcomingEvents {...this.props}/>
                <PhotoGallery {...this.props}/>
                <GPSTeam {...this.props}/>
                <FAQs {...this.props}/>
                <Connect {...this.props} />
            </>
        )
    }
}

export default LandingPage;