import React from "react";
import { useParams } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";
import PropTypes from 'prop-types';
import Connect from "../landingpage/Connect";


/**
 * GPS Member Blog Page Component.
 */
const BlogPage = (props) => {
    
    const { id } = useParams();
    
    let workingblog = {};
    props.blogs.map((b) => {
        if (parseInt(b.id) === parseInt(id)) {
            workingblog = b
        }
        return parseInt(b.id) === parseInt(id) ? b : {};
    })
    props.setWorkingBlog(workingblog)
      
    let fulldesc = props.workingblog && props.workingblog.fulldesc ? props.workingblog.fulldesc.replace(/<p>/g, '<div style="color:#637381;padding-bottom:20px;">').replace(/<\/p>/g, '</div>') : '';
      
    return (
        <>
            <section className="bg-white mt-[3%] pb-30 mb-[7%]">
                <div className="container">
                    <div className="col-span-12 pt-20 pb-0 text-left" >
                        <div className="flex flex-row space-x-2">
                            <div className="text-tprimary text-sm font-bold">Blog</div>
                            <div className="pt-[5px]">
                                <GoDotFill size={10}/>
                            </div>
                            <p className="text-ltgray text-sm">{props.workingblog.desc}</p>
                        </div>
                        <div className="flex flex-row mt-[20px] mb-[20px] gap-[40px]">
                            <div className="bg-bgltblue cursor-pointer border-bgblue border-0 border-solid p-[10px] flex min-w-2 xl:h- justify-center items-center px-[20px] rounded-[30px]">
                                <div className="self-center text-bgblue text-sm">{props.workingblog.cat}</div>
                            </div>
                        </div>
                        <h3 className="text-left text-tprimary">{props.workingblog.title}</h3>
                        <div className="text-sm text-tsecondary">{props.workingblog.date}</div>
                        
                        <div className="mt-3">
                            {props.workingblog && props.workingblog.img ? <img className="w-full  cursor-pointer object-cover object-top  md:h-40 lg:h-[45vh] rounded-2xl" src={props.workingblog.img} alt="blog" style={{borderRadius:"1rem !important"}}/> : <></>}
                        </div>
                        
                    </div>
                    <div className="flex flex-row space-x-10">
                        <div className="w-[68%] justify-end">
                            <div className="mt-10">

                                <div dangerouslySetInnerHTML={{ __html: fulldesc}} className="text-1xl text-tprimary leading-8 text-justify"></div>
                            </div>
                        </div>
                        <div className="w-[28%] justify-end">
                            <div className="mt-10 text-left hidden">
                                <h4 className="font-bold">You may also like</h4>
                            </div>
                            <article className="mb-20 hidden">
                                <div className="mt-3">
                                    <img className="w-full  cursor-pointer object-cover object-top  md:h-40 lg:h-40 rounded-2xl" src="/assets/images/topicholder.png" alt="blog" style={{borderRadius:"1rem !important"}}/>
                                </div>
                                <div className="flex flex-row mt-[20px] mb-[20px] gap-[40px]">
                                    <div className="bg-bgltblue cursor-pointer border-bgblue border-0 border-solid p-[10px] flex min-w-2 xl:h- justify-center items-center px-[20px] rounded-[30px]">
                                        <div className="self-center text-bgblue text-sm">Topic</div>
                                    </div>
                                </div>
                                <div className="text-left leading-9">
                                    <h4>Lorem ipsum dolor sit amet cosectur lorem ipsum dolor</h4>
                                </div>
                            </article>

                            <article className="hidden">
                                <div className="mt-3">
                                    <img className="w-full  cursor-pointer object-cover object-top  md:h-40 lg:h-40 rounded-2xl" src="/assets/images/topicholder.png" alt="blog" style={{borderRadius:"1rem !important"}}/>
                                </div>
                                <div className="flex flex-row mt-[20px] mb-[20px] gap-[40px]">
                                    <div className="bg-bgltblue cursor-pointer border-bgblue border-0 border-solid p-[10px] flex min-w-2 xl:h- justify-center items-center px-[20px] rounded-[30px]">
                                        <div className="self-center text-bgblue text-sm">Topic</div>
                                    </div>
                                </div>
                                <div className="text-left leading-9">
                                    <h4>Lorem ipsum dolor sit amet cosectur lorem ipsum dolor</h4>
                                </div>
                            </article>
                        </div>
                    </div>
                    
                </div>
            </section>
            <Connect {...props} bgblue={true}/>
        </>
    )
    
}
export default BlogPage;

BlogPage.propTypes = {
	/** ID of the blog that is in the querystring */
	id: PropTypes.string,
    /** Blog selelcted from ID in the querystring.  Set first in workingblog variable */
    blogData: PropTypes.array
};

BlogPage.defaultProps = {
	id: '',
    blogData: []
};