import React from "react";
import PropTypes from 'prop-types';
/**
 * GPS Member Portal Header.
 */
const Header = (props) => {
    
    const [menuopen, setMenuOpen] = React.useState(false);
    const handleLogOut = (event, props) => {
        props.setcookie('gpsuser', '', { path: '/' })
        props.setlogin(false);
        props.setuserinfo({});
        props.setMemberDonorInfo('');
        props.setworkingmember({});
        props.setworkingtransaction({});
        props.setGiftInfo([]);
        props.setListItems([]);
    }
    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };
    const handleToggle = () => {
        if (menuopen) {
            setMenuOpen(false);
        } else {
            setMenuOpen(true)
        }
    }
    let isOpen = props.windowwidth <= 844 ? true : false;
    return (
        <>
            <nav className={`bg-white fixed w-full z-20 top-0  ${isOpen ? '' : 'hidden'}`}>
                <div className="container"> 
                    <div id="nav-container" className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto ">
                        <div className="col-md-4 flex items-center space-x-10 rtl:space-x-reverse">
                            <img src="https://gpspartner.org/wp-content/uploads/sites/6/2017/09/gpslogo.png" className="max-w-[150px] cursor-pointer" alt="GPS Membership Portal" onClick={(e)=> window.location.href='/'} />
                            
                        </div>
                        <div className="text-right col-md-6">
                            <ul className="font-medium flex flex-wrap p-0 justify-between space-x-2">
                              
                                <li className="pt-5">
                                    <input type="submit" value="Donate" className="bg-bgblue hover:bg-bgbluehover text-left text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer" onClick={() =>openInNewTab("https://us.mobileaxept.com/ims/mobileaxept/donate.jsp?&org_code=TDJ008&sgr=119&utm_medium=online")}/>
                                </li>
                                <li className="pt-5">
                                    <button className="bg-transparent border-solid border-1 border-tprimary hover:bg-bgbluehover text-left text-tprimary font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={(e) => handleLogOut(e, props)}>Log Out</button>
                                </li>
                                <li className="pt-5">
                                    <img className="cursor-pointer" src="/assets/images/icon_menubutton.png" onClick={(e) => handleToggle()} alt="GPS Member Portal"/>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className={`${!menuopen ? 'hidden' : ''}`}>
                        <ul>
                            <li className="pt-4 pb-4 text-left border-b-2 border-dotted">
                                <a href="/" className="text-tprimary hover:text-bgbluehover">Member Area</a>
                            </li>
                            <li className="pt-4 pb-4 text-left border-b-2 border-dotted">
                                <a href="/profile" className="text-tprimary hover:text-bgbluehover">My Partner Account</a>
                            </li>
                            <li className="pt-4 pb-4 text-left border-b-2 border-dotted">
                                <a href="/blog" className="text-tprimary hover:text-bgbluehover">Blog</a>
                            </li>
                            <li className="pt-4 pb-4 text-left border-b-2 border-dotted">
                                <a href="/networking" className="text-tprimary  hover:text-bgbluehover">Networking</a>
                            </li>
                            <li className="pt-4 pb-4 text-left border-b-2 border-dotted">
                                <a href="https://shop.tdjakes.org" className="text-tprimary  hover:text-bgbluehover" target="_blank" rel="noreferrer">Online Store</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <nav className={`bg-white fixed w-full z-20 top-0  ${isOpen ? 'hidden' : ''}`}>
                <div className="container"> 
                    <div  id="nav-container" className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto ">
                        <div className="col-md-3  items-center space-x-10 rtl:space-x-reverse">
                            <img src="https://gpspartner.org/wp-content/uploads/sites/6/2017/09/gpslogo.png" className="max-w-[225px] cursor-pointer" alt="GPS Membership Portal" onClick={(e)=> window.location.href='/'} />
                            <p className="text-tprimary" >Welcome to the Partnership Portal</p>
                        </div>
                        <div id="header-nav-container" className="text-right col-md-7">
                            <ul className="font-medium flex flex-wrap p-0 justify-between space-x-2">
                                <li className="pt-5">
                                    <a className="text-tprimary font-semibold hover:text-bgbluehover" href="/">Member Area</a>
                                </li>
                                <li className="pt-5">
                                    <a href="/profile" className="text-tprimary font-semibold hover:text-bgbluehover">My Partner Account</a>
                                </li>
                                <li className="pt-5">
                                    <a href="/blog" className="text-tprimary font-semibold hover:text-bgbluehover">Blog</a>
                                </li>
                                <li className="pt-5">
                                    <a href="/networking" className="text-tprimary font-semibold hover:text-bgbluehover">Networking</a>
                                </li>
                                <li className="pt-5">
                                    <a href="https://shop.tdjakes.org" className="text-tprimary font-semibold hover:text-bgbluehover" target="_blank" rel="noreferrer">Online Store</a>
                                </li>
                                <li className="pt-5">
                                    <input type="submit" value="Give" className="bg-bgblue hover:bg-bgbluehover text-left text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer" onClick={() =>openInNewTab("https://us.mobileaxept.com/ims/mobileaxept/donate.jsp?&org_code=TDJ008&sgr=119&utm_medium=online")}/>
                                </li>
                                <li className="pt-5">
                                    <button className="bg-transparent border-solid border-1 border-tprimary hover:bg-bgbluehover text-left text-tprimary font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={(e) => handleLogOut(e, props)}>Log Out</button>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Header;