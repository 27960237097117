import React from "react";
import BlogItems from "../blog/BlogItems";

/**
 * GPS Member Outreach Efforts Component. - This section holds a video and button to impact blog item.
 */
const OutreachEfforts = (props) => {
   
        return (
            <>
                <section className="bg-white ">
                    <div className={`grid   gap-5 ${props.isMobile ? 'grid-cols-1' : 'grid-cols-4'}`}>
                        <div className={`bg-outreach bg-fixed bg-center bg-contain bg-no-repeat ${props.isMobile ? 'hidden' : ''}`}></div>
                        <div className={`pt-20 pb-20 text-left ${props.isMobile ? 'col-span-2' : 'col-span-2'}`}>
                            <div className="w-full max-w-full  bg-white border border-gray-200 rounded-lg shadow-gcard dark:bg-gray-800 dark:border-gray-700 p-3">
                               
                                <p className="font-bold text-tprimary">GPS</p>
                                <p className="text-tprimary">Dallas, Texas, United States</p>
                                <div className="relative pt-[56.25%]">
                                    <iframe className="absolute inset-0 w-full h-full" src="https://players.sardius.media/560DCB3154E5c7E/dvr/asset/7524dc370b3C29CDdF65C1015043" frameBorder="0" title="GPS Member Video"></iframe>
                                </div>
                                
                                <p className="mt-[20px] leading-6 text-tprimary"><em>"Thank you for your support. Everywhere I turn, I feel the need to do more to help those who need us the most."</em><br/><br/>
                                –BISHOP T.D. JAKES</p>
                            </div>
                            
                        </div>
                        <div id="outReach-container" className={`col-span-1  pb-10 ${props.isMobile ? 'pt-0' : 'pt-[25%]'}`}></div>
                    </div>
                </section>
                <section className="bg-white ">
                    <div className="container">
                        <div className="mt-[5%] mb-[5%] text-center">
                            <h1 className="text-tprimary font-bold ">Outreach Efforts</h1>
                            <div className="flex justify-center max-w-lg flex-col mx-auto">
                                <p className="text-tsecondary leading-6 text-justify">
                                Your partnership with us is essential in enabling us to deliver vital services and resources to those in need around the world. With your support, we provide food, disaster relief, ministry resources, and so much more.
                                </p>
                            </div>
                            <BlogItems {...props} topic='outreach' />
                            <button className="bg-transparent border-solid border-1 border-tprimary hover:bg-bgbluehover text-center text-tprimary font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={(e)=> window.location.href='/blog'} >View More</button>
                        </div>
                    </div>
                </section>
            </>
        )
    
}
export default OutreachEfforts;