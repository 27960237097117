import React, { useState } from 'react';

const ChatFooter = ({ socket }) => {
  const [message, setMessage] = useState('');
  const [sendMessage, setSendMessage] = useState(false);
  let typingTimer;
  //var doneTypingInterval = 5000;

  const handleTyping = () => {
    socket.emit('typing', `${localStorage.getItem('userName')} is typing`);
    clearTimeout(typingTimer);
    setSendMessage(true);
  }

  // useEffect(() => {
  //   if(sendMessage) {
  //     setTimeout(() => {
  //       setSendMessage(false);
  //     },doneTypingInterval)
  //   }
  // }, [sendMessage])

//     const handleStopTyping = () => {
//         clearTimeout(typingTimer);
//   typingTimer = setTimeout(doneTyping, doneTypingInterval);
//     };

    // const doneTyping = () => {
    //   socket.emit('stopTyping');
    // };
    const handleSendMessage = (e) => {
        e.preventDefault();
        if (message.trim() && localStorage.getItem('userName')) {
          socket.emit('message', {
            text: message,
            name: localStorage.getItem('userName'),
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id,
          });
        }
        setMessage('');
      };
  return (


    <div className="chat__footer">
    <form className="form" onSubmit={handleSendMessage}>
      <input
        type="text"
        placeholder="Enter a message..."
        className="message"
        value={message}
        multiple
        required
        onChange={(e) => setMessage(e.target.value)}
        // onKeyUp={handleStopTyping}       
        onKeyDown={handleTyping}
      /> {
        sendMessage ? <span id="send-btn" className="material-symbols-outlined" onClick={handleSendMessage}>send</span> : null
      }
      
    </form>
  </div>
  );
};

export default ChatFooter;