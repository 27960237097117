import React from "react";
//import SharedFunctions from "../classes/sharedfunctions";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

//const shared = new SharedFunctions();
class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {pageInfo: this.props.pInfo};
        this.createElements = this.createElements.bind(this);
        
    }
    
    createElements (number) {
        number = this.props.pInfo.total_pages;
        var elements = [];
        for(let i = 1; i <= number; i++){
            if (i === this.props.pagenum) {
                elements.push(<div key={Math.random()}  aria-current="page" className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer" onClick={(e) => this.props.handleGotoPage(e, i)}>{i}</div>);
            } else {
                elements.push(<div key={Math.random()}  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer" onClick={(e) => this.props.handleGotoPage(e, i)}>{i}</div>);
            }   
        }
        return elements;
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-12 gap-4 mt-[5%] border-t border-gray-200 bg-white py-3">
                    <div className="col-span-8">
                        <div className="flex flex-1 items-center justify-between">
                            <div>
                            <p className="text-sm text-gray-700">
                                Showing <span className="font-medium">{(this.props.pInfo.page * this.props.pInfo.per_page) - this.props.pInfo.per_page !== 0 ? (this.props.pInfo.page * this.props.pInfo.per_page) - this.props.pInfo.per_page : 1}</span> to <span className="font-medium">{this.props.pInfo.page * this.props.pInfo.per_page < this.props.pInfo.total ? this.props.pInfo.page * this.props.pInfo.per_page : this.props.pInfo.total}</span> of <span className="font-medium">{this.props.pInfo.total}</span> results
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className="flex flex-1 justify-end space-x-2">
                            <div onClick={(e) => this.props.handleGotoPage(e, 1)} className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer">
                                <span className="sr-only">First Page</span>
                                <MdKeyboardDoubleArrowLeft size="20" />
                            </div>
                            <button className="relative inline-flex items-center rounded-md border border-bgblue bg-bgblue px-8 py-2 text-sm font-medium text-white hover:bg-bgbluehover cursor-pointer" onClick={(e) => this.props.handleGotoPage(e, this.props.pInfo.pre_page)}>Previous</button>
                            <button className="relative inline-flex items-center rounded-md border border-bgblue bg-bgblue px-8 py-2 text-sm font-medium text-white hover:bg-bgbluehover cursor-pointer" onClick={(e) => this.props.handleGotoPage(e, this.props.pInfo.next_page)}>Next</button>
                            <div onClick={(e) => this.props.handleGotoPage(e, this.props.pInfo.total_pages)}  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer">
                                <span className="sr-only">Last Page</span>
                                <MdKeyboardDoubleArrowRight size="20" />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="hidden flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                    
                    <div className="sm:flex sm:flex-1 sm:items-center sm:justify-center justify-center">
                        
                        <div>
                            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                <div onClick={(e) => this.props.handleGotoPage(e, 1)} className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer">
                                <span className="sr-only">Previous</span>
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                                </div>
                                {this.createElements(this.props.pageInfo.total_pages)}
                            
                                <div onClick={(e) => this.props.handleGotoPage(e, this.state.pageInfo.total_pages)}  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                <span className="sr-only">Next</span>
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>



            </>
        )
    }
}

export default Pagination;