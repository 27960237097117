import React, { useState } from "react";
//import ChatBar from "../molecule/ChatBar";
//import ChatBody from "../molecule/ChatBody";
//import ChatFooter from "../molecule/ChatFooter";
//import { io } from "socket.io-client";
//import ChatProfileImage from "../atoms/ChatProfileImage";
import Chat from "./Chat";
import SocketIo from '../atoms/SocketIo';
import { v4 } from 'uuid';
// import { ChatEngine } from 'react-chat-engine';

const ChatPage = () => {
  const [username, setUsername] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const [user, setUser] = useState({
    username: "",
    userId: "",
    roomId: "",
    socketId: "",
    color: "",
  })

  const handleChange = (e) => {
    setUsername(e.target.value);
  };
  const openChatRoom = () => {
    SocketIo.connect({query: { ...user}});
    setUser({...user, username: username, userId: v4()})
    setChatOpen(true);
  };


  return (
    <>
    {chatOpen ? <Chat user={user} socket={SocketIo} userName={username}/> :  <div style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <h1>Select a userName: must be unique***</h1>
      <input onChange={(e) => handleChange(e)} placeholder="type a username" style={{marginLeft: 20, borderColor: 'black', borderStyle: 'solid', borderWidth: 2}} name="userName" type="text"/>
      <button onClick={openChatRoom}>Enter Chat room</button>
      </div>}
   
      </>
  );
};

export default ChatPage;


