import * as React from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module'
import { decodeToken } from "react-jwt";
import Header from './components/Header';
import Login from './components/Login';
import LandingPage from './components/landingpage/LandingPage';
import MemberProfile from './components/memberprofile/Profile';
import ViewProfile from './components/memberprofile/ViewProfile';
import ProfileAccept from './components/memberprofile/ProfileAccept';
import BlogList from './components/blog/BlogList';
import BlogPage from './components/blog/BlogPage';
import Networking from './components/networking/Networking';
import Footer from './components/Footer';
import Connect from './components/landingpage/Connect';
import ProgressBar from './components/ProgressBar';
import ChatPage from './components/organism/ChatPage';
import Rests from './components/rests/services';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import variables from './components/rests/variables';
//import socketIO from 'socket.io-client';

//import logo from './logo.svg';
import './App.css';
const rests = new Rests();


/**
 * Main function that initializes the GPS Member Portal
 * 
 * 
 */
function App() {
  const [loggedIn, setLogin] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['gpsuser']);
  const [userInfo, setUserInfo] = React.useState('');
  const [memberdonorInfo, setMemberDonorInfo] = React.useState('');
  const [workingmember, setWorkingMember] = React.useState({});
  const [workingtransaction, setWorkingTransaction] = React.useState({});
  const [giftInfo, setGiftInfo] = React.useState([]);
  const [showprogress, setShowProgress] = React.useState(false);
  const [connecting, setConnecting] = React.useState(false);
  const [progresstxt, setProgressTxt] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [portalmemnum, setPortalMemNum] = React.useState(0);
  const [per_page, setPerPage] = React.useState(20);
  const [items, setItems] = React.useState([]);
  const [listitems, setListItems] = React.useState([]);
  const [pageInfo, setPageInfo] = React.useState({});
  const [networkitems, setNetworkItems] = React.useState([]);
  const [windowwidth, setWindowWidth] = React.useState(window.innerWidth);
  const [isMobile, setIsMobile] = React.useState(false);
  const [gatheringnetworkitems, setGatheringNetworkItems] = React.useState(false);
  const [teammembers, setTeamMembers] = React.useState([]);
  const [faqs, setFaqs] = React.useState([]);
  const [contactInfo, setContactInfo] = React.useState([]);
  const [newsletter, setNewsletter] = React.useState([]);
  const [blogs, setBlogs] = React.useState([]);
  const [bloglist, setBlogList] = React.useState([]);
  const [blogtabs, setBlogTabs] = React.useState([]);
  const [workingblog, setWorkingBlog] = React.useState([]);
  const tagManagerArgs = {gtmId: 'GTM-P5DD8STV'}
  
  
  let addprops = {
    loggedIn: loggedIn,
    setlogin: setLogin,
    cookies: cookies,
    setcookie: setCookie,
    removecookie: removeCookie,
    userInfo: userInfo,
    setuserinfo: setUserInfo,
    memberdonorInfo: memberdonorInfo,
    setMemberDonorInfo: setMemberDonorInfo,
    workingmember: workingmember,
    setworkingmember: setWorkingMember,
    workingtransaction: workingtransaction,
    setworkingtransaction: setWorkingTransaction,
    giftInfo: giftInfo,
    setGiftInfo: setGiftInfo,
    showprogress: showprogress,
    setshowprogress: setShowProgress,
    progresstxt: progresstxt,
    setprogresstxt: setProgressTxt,
    page: page,
    setPage: setPage,
    per_page: per_page,
    setPerPage: setPerPage,
    items: items,
    listitems: listitems,
    setListItems: setListItems,
    setItems: setItems,
    pageInfo: pageInfo,
    setPageInfo: setPageInfo,
    networkitems: networkitems,
    setnetworkitems: setNetworkItems,
    portalmemnum: portalmemnum,
    setportalmemnum: setPortalMemNum,
    windowwidth: windowwidth,
    setWindowWidth: setWindowWidth,
    isMobile: isMobile,
    setIsMobile: setIsMobile,
    gatheringnetworkitems: gatheringnetworkitems,
    setGatheringNetworkItems: setGatheringNetworkItems,
    teammembers: teammembers,
    setTeamMembers: setTeamMembers,
    faqs: faqs,
    setFaqs: setFaqs,
    contactInfo: contactInfo,
    setcontactinfo: setContactInfo,
    newsletter: newsletter,
    setnewsletter: setNewsletter,
    blogs: blogs,
    setBlogs: setBlogs,
    bloglist: bloglist,
    setBlogList: setBlogList,
    blogtabs: blogtabs,
    setBlogTabs: setBlogTabs,
    workingblog: workingblog,
    setWorkingBlog: setWorkingBlog,
    connecting: connecting,
    setConnecting: setConnecting
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: cookies.gpsuser ? <LandingPage {...addprops}/> : <Login {...addprops}/>,
    },
    {
      path: "/profile",
      element: cookies.gpsuser ? <MemberProfile {...addprops} /> : <Login {...addprops} />,
    },
    {
      path: "/profile/:id",
      element: cookies.gpsuser ? <ViewProfile {...addprops} /> : <Login {...addprops} />,
    },
    {
      path: "/profileaccept/:id",
      element: cookies.gpsuser ? <ProfileAccept {...addprops} /> : <Login {...addprops} />,
    },
    {
      path: "/blog",
      element: cookies.gpsuser ? <BlogList {...addprops} /> : <Login {...addprops} />,
    },
    {
      path: "/blog/:id",
      element: cookies.gpsuser ? <BlogPage {...addprops} /> : <Login {...addprops} />,
    },
    {
      path: "/networking",
      element: cookies.gpsuser ? <Networking {...addprops} /> : <Login {...addprops} />,
      state: { userInfo: userInfo }
    },
    {
      path: "/networking/chat",
      element: cookies.gpsuser ? <ChatPage /> : <Login {...addprops} />
    }
  ])
  
  React.useEffect(() => {
    //Set up Google Tag Manager
    TagManager.initialize(tagManagerArgs);
    
    let location = window.location.href;
    
    if (cookies.gpsuser) {
     
      let userInfo = JSON.parse(JSON.stringify(cookies.gpsuser));
      if (userInfo.p) {
        
        setLogin(true);
        setPortalMemNum(parseInt(userInfo.p));

      }
      if (!giftInfo.length) {
        const aftergifts = (widgetItems) => {
          
          setGiftInfo(widgetItems);
        }
        let url = variables.buProcessService + '/ecom/latestinprov?staging=1&type=gps';
       
        //rests.processrest (url, aftergifts, 'get', giftInfo);
      }
      
    }

    const updateWindowDimensions = () => {
      let w = window.innerWidth;
     
      if (w <= 844) {
        setWindowWidth(w);
        setIsMobile(true);
      } else {
        setWindowWidth(w);
        setIsMobile(false)
      }
      
    }
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    const getTeamMembers = () => {
      const afterteammembers = (tms) => {
        
        setTeamMembers(tms);
      }
      let url = "https://www.gpspartner.org/wp-json/gpsdata/v1/teammembers";
      rests.processrest (url, afterteammembers, 'get', {});
    }

    if (!teammembers.length) {
      getTeamMembers();
    }

    const getFaqs = () => {
      const afterfaqs = (faqItems) => {
        setFaqs(faqItems);
      }
      let url = "https://www.gpspartner.org/wp-json/gpsdata/v1/faqs";
      rests.processrest (url, afterfaqs, 'get', {});
    }

    if (!faqs.length) {
      getFaqs();
    }

    const getBlogs = () => {
      const afterblogs = (blogItems) => {
        
        let bl = {};
        let btabs = [];
        let tabcnt = 1;
        for (let b in blogItems) {
          let blogObj = blogItems[b];
          let catslug = blogObj.catslug;
          let catname = blogObj.cat;
          if (!bl[catslug]) {
            bl[catslug] = [];
            bl[catslug].push(blogObj);
            btabs.push({cat: catname, id: tabcnt, slug: catslug})
            tabcnt++;
          } else {
            bl[catslug].push(blogObj);
          }
          
        }
        
        setBlogList(bl);
        setBlogTabs(btabs);
        setBlogs(blogItems);
        
        
      }
      let url = "https://gpspartner.org/wp-json/gpsdata/v1/blogs";
      rests.processrest (url, afterblogs, 'get', {});
    }

    if (!blogs.length) {
      getBlogs();
    }

    const getNewsletter = () => {
      const afternews = (newsInfo) => {
        setNewsletter(newsInfo);
      }
      let url = "https://gpspartner.org/wp-json/gpsdata/v1/newsletter";
      rests.processrest (url, afternews, 'get', {});
    }

    if (!newsletter.length) {
      getNewsletter();
    }

    const getContactInfo = () => {
      const aftercontact = (contactInfo) => {
        setContactInfo(contactInfo);
      }
      let url = "https://gpspartner.org/wp-json/gpsdata/v1/contactinfo";
      rests.processrest (url, aftercontact, 'get', {});
    }

    if (!contactInfo.length) {
      getContactInfo();
    }

    

    const getNetworkingMembers = () => {
      ///netmems
      setGatheringNetworkItems(true)
      const afternetmems = (nmems) => {
        if (nmems && nmems.nwms) {
          let networkInfo = decodeToken(nmems.nwms);
          
          for (let n in networkInfo.nms) {
            networkInfo.nms[n]['connecting'] = false;
            networkInfo.nms[n]['connectiontxt'] = '';
          }
          
          setNetworkItems(networkInfo.nms);
          setGatheringNetworkItems(false)
        }
      }
      let url = variables.buProcessMemberService + '/api/netmems';
      
      rests.processrest (url, afternetmems, 'get', {});
    }

   
    if (location && location.indexOf('networking') !== -1) {
      getNetworkingMembers();
    }
    
  }, [])


  return (
    <div className="App">
      <CookiesProvider>
          {cookies.gpsuser ? <Header {...addprops}/> : <></>}
          {/* {cookies.gpsuser ? <ChatToggle /> : <></>} */}
          <RouterProvider router={router} {...addprops} />
          <ProgressBar {...addprops}/>
        </CookiesProvider>
    </div>
  );
}

export default App;
/*[3:17 PM] Jessica Barber
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-09RF0M4SXL"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
 
  gtag('config', 'G-09RF0M4SXL');
</script>*/