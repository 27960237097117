import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
//import PropTypes from 'prop-types';

const BlogItems = (props) => {
    
    return (
        <div className="col-span-12 pb-0">
            <div className={`grid  gap-4 ${props.isMobile ? 'grid-cols-1' : 'grid-cols-3'}`}>
                {props.bloglist && props.bloglist[props.topic] ? props.bloglist[props.topic].map((litem, idx) => {
                    return (
                        <article key={Math.random()} className="flex flex-col items-left p-3 h-full overflow-hidden">
                            <img className="w-full  cursor-pointer object-cover object-center  md:h-36 lg:h-60 rounded-2xl" src={litem.img} alt="blog" style={{borderRadius:"1rem !important"}} onClick={(e)=> window.location.href='/blog/' + litem.id}/>
                            

                            <div className="flex flex-row mt-[20px] mb-[20px] gap-[40px]">
                                <div className="bg-bgltblue cursor-pointer border-bgblue border-0 border-solid p-[10px] flex min-w-2 xl:h- justify-center items-center px-[20px] rounded-[30px]">
                                    <div className="self-center text-bgblue text-sm">{litem.cat}</div>
                                </div>
                            </div>
                            <h4 className="text-left text-tprimary">{litem.title}</h4>
                            <p className="text-left text-tsecondary line-clamp-6 mb-3 cursor-pointer overflow-hidden leading-relaxed text-gray-500">{litem.desc}</p>
                        
                            <div className="text-right flex flex-row justify-between">
                                <div className="flex flex-wrap text-sm text-gray-500">
                                    <span className="mr-1">{litem.date}</span>
                                </div>
                                <button className="bg-transparent flex flex-row justify-end text-tanchor text-sm items-right w-1/3 border-1 rounded-xl  font-bold" onClick={(e)=> window.location.href='/blog/' + litem.id} >Read <MdOutlineKeyboardArrowRight size={20} /></button>
                            </div>

                        </article>
                    )
                }) : <><div className="col-span-3 text-left"><h4>There are current no items to view under this topic.</h4></div></>}
                
                
            </div>
        </div>
    )
}

export default BlogItems;