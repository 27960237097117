import React from "react";
import { FaRegUser } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiGitconnected } from "react-icons/si";
import { FaYoutube } from "react-icons/fa6";
import { FaGlobe } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { decodeToken } from "react-jwt";
import { BiSolidUserDetail } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import Connect from "../landingpage/Connect";
import variables from "../rests/variables";
import Rests from "../rests/services";
const rests = new Rests();

class MemberBlock extends React.Component {
    constructor(props) {
        super(props);
        let fb = this.props.ssms && this.props.ssms.length ? this.props.ssms.filter(sm=> sm.type === 'facebook') : [];
        let insta = this.props.ssms && this.props.ssms.length ? this.props.ssms.filter(sm=> sm.type === 'instagram') : [];
        let lkin = this.props.ssms && this.props.ssms.length ? this.props.ssms.filter(sm=> sm.type === 'linkedin') : [];
        let twit = this.props.ssms && this.props.ssms.length ? this.props.ssms.filter(sm=> sm.type === 'twitter') : [];
        let yt = this.props.ssms && this.props.ssms.length ? this.props.ssms.filter(sm=> sm.type === 'youtube') : [];
        let web = this.props.ssms && this.props.ssms.length ? this.props.ssms.filter(sm=> sm.type === 'website') : [];

        
        let partnercode = this.props.tm.partner ? this.props.tm.partner.toLowerCase() : '';
        this.badgeImg = partnercode && this.props.badges[partnercode] ? this.props.badges[partnercode].badge : '';
        
        this.state = {fb: fb, insta: insta, lkin: lkin, twit: twit, yt: yt, web: web};
        this.getNetworkingMembers = this.getNetworkingMembers.bind(this);
        this.myStyle = {
            backgroundImage:
                "url('" + this.props.tm.photo + "')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: '0.5rem'
        };

        this.badgeStyle = {
            backgroundImage:
                "url('" + this.badgeImg + "')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            paddingBottom: "30%"
        };
    }

    getNetworkingMembers () {
        ///netmems
        const afternetmems = (nmems) => {
            console.log(nmems)
        }
        let url = variables.buProcessMemberService + '/api/netmems';
        
        rests.processrest (url, afternetmems, 'get', {});
    }

    render() {
        return (
            <div className="w-full max-w-full  bg-white border border-gray-200 rounded-lg shadow-gcard dark:bg-gray-800 dark:border-gray-700 pt-10">
                <div className="flex flex-col items-center pb-3 relative">
                    <div className="absolute z-10 w-[20%] bg-right right-[5%] top-[13%]" style={this.badgeStyle}></div>
                    <h5 className="mb-1 text-xl font-medium text-tprimary">{this.props.tm.firstname + " " + this.props.tm.lastname}</h5>
                    <div className="mt-1 mb-1 m-h-[100px]">
                        {this.props.tm.showcompany ? <div className="text-ltgray text-sm leading-6 ">{this.props.tm.company ? this.props.tm.company : <span>&nbsp;</span>}</div>: <div className="text-ltgray text-sm leading-6 ">&nbsp;</div>}
                        {this.props.tm.showtitle ?<div className="text-ltgray text-sm leading-6 ">{this.props.tm.title ? this.props.tm.title : <div>&nbsp;</div>}</div>:<div className="text-ltgray text-sm leading-6 ">&nbsp;</div>}
                    </div>
                    {this.props.tm.photo && parseInt(this.props.tm.photoapproved) ? <div id="networking-page-image" className="rounded-md w-[90%] max-h-[300px] min-h-[300px] pb-50" style={this.myStyle}></div> : <div className="rounded-md w-[90%] max-h-[300px] min-h-[300px] pb-50 bg-gray-200 text-center"><FaRegUser className="w-[80%] max-h-[300px] min-h-[300px] text-white m-auto"/></div>}
                        
                    
                    
                    {this.props.tm.openconnection && !this.props.tm.connected ? <div className="grid grid-cols-2 md:grid-cols-2 gap-3 px-1 mt-8 w-[90%]"><input type="button" value="View Profile" className="w-[100%] bg-bgblue hover:bg-bgbluehover text-center text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline text-center cursor-pointer" onClick={(e) => this.props.viewProfile(this.props.tm.Id)}/><input type="button" value="Connect" className="w-[100%] bg-bgblue hover:bg-bgbluehover text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-center cursor-pointer" onClick={(e)=> this.props.requestconnection(this.props.tm.membernumber)}/></div> : <div className="w-100 relative"><input type="button" value="View Profile" className="w-[90%] bg-bgblue hover:bg-bgbluehover text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-8 text-center cursor-pointer" onClick={(e) => this.props.viewProfile(this.props.tm.Id)}/> {this.props.tm.connected ? <div className="absolute top-[58%] right-[8%] "><SiGitconnected size="20" className="text-white"/></div> : <></>} </div>}

                    
                        
                    <ul className="font-medium flex flex-wrap p-0 justify-between space-x-3 mt-3 min-h-[30px]">
                        {this.state.fb.length ? <li>
                            <FaFacebookF className="text-ssmblue cursor-pointer" size="18" onClick={(e) => this.props.openInNewTab(this.state.fb[0].link)}/>
                        </li> : <></>}

                        {this.state.insta.length ? <li>
                            <FaSquareInstagram className="text-ssmpink cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.state.insta[0].link)}/>
                        </li> : <></>}

                        {this.state.lkin.length ? <li>
                            <FaLinkedinIn className="text-ssmdrkblue cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.state.lkin[0].link)}/>
                        </li> : <></>}

                        {this.state.twit.length ? <li>
                            <FaSquareXTwitter className="text-tprimary cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.state.twit[0].link)}/>
                        </li> : <></>}
                        
                        {this.state.yt.length ? <li>
                            <FaYoutube className="text-ssmred cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.state.yt[0].link)}/>
                        </li> : <></>}

                        {this.state.web.length ? <li>
                            <FaGlobe className="text-ssmltblue cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.state.web[0].link)}/>
                        </li> : <></>}

                        {this.props.tm.showemail ? <li>
                            <a href={"mailto:" + this.props.tm.email}><MdEmail className="text-ssmltblue" size="20"/></a>
                        </li> : <></>}
                        
                        
                    </ul>
                    
                    {this.props.tm.connectiontxt ? <p id="connection" className="text-tprimary absolute bottom-0" dangerouslySetInnerHTML={
                        { __html: this.props.tm.connectiontxt }}></p> : <></>}
                    
                </div>
            </div>
        )
    }
};

class NetworkingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        let partnercode = this.props.wm.partner ? this.props.wm.partner.toLowerCase() : '';
        this.badgeImg = partnercode && this.props.badges[partnercode] ? this.props.badges[partnercode].badge : '';
        this.badgeStyle = {
            backgroundImage:
                "url('" + this.badgeImg + "')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            paddingBottom: "30%"
        };
        let ssms = this.props.wm.ssms ? JSON.parse(this.props.wm.ssms ) : []
        this.fb = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'facebook') : [];
        this.insta = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'instagram') : [];
        this.lkin = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'linkedin') : [];
        this.twit = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'twitter') : [];
        this.yt = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'youtube') : [];
        this.web = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'website') : [];
        
    }
    

    render () {
        return (
            <section className="bg-white mt-[4%] pb-30 mb-[4%]">
               
                <div className="container">
                    <div className="col-span-12 pt-20 pb-0 text-left" >
                        <h3 className="text-tprimary font-bold">{this.props.wm.firstname} {this.props.wm.lastname}</h3>
                        <div className="flex flex-row space-x-2">
                            <div className="text-tprimary text-sm cursor-pointer" onClick={(e)=> this.props.returntolist()}>Networks</div>
                            <div className="pt-[5px]">
                                <GoDotFill size={10}/>
                            </div>
                            <p className="text-ltgray text-sm">{this.props.wm.firstname} {this.props.wm.lastname}</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-5 mt-[3%]">
                        <div className="col-span-1 text-center">
                            <div className="w-full max-w-full  bg-white border border-gray-200 rounded-lg shadow-gcard dark:bg-gray-800 dark:border-gray-700 py-5 relative">
                                <div className="relative">
                                    {this.props.wm.photo && parseInt(this.props.wm.photoapproved) ? <img className="w-[80%] max-h-[300px]  m-auto rounded-circle border border-gray-200" src={this.props.wm.photo} alt="GPS Member"/> : <div className="relative bg-ltgray p-3 rounded-full w-[80%]  text-center m-auto"><FaRegUser className="w-[80%] h-[80%] rounded-circle text-white m-auto"/></div>}
                                    <div className="absolute z-10 w-[20%] bg-right left-[1%] bottom-0 " style={this.badgeStyle}></div>
                                </div>

                                <div className=" text-left px-5">
                                    <h5 className="text-tprimary font-bold mt-[5%]">Contacts</h5>
                                    <div className="flex flex-row space-x-5">
                                        <div className="col-span-1">
                                            <MdEmail size="20" className="text-tprimary text-sm"/>
                                        </div>
                                        <div className="col-span-7">{this.props.wm.email}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full max-w-full  bg-white border border-gray-200 rounded-lg shadow-gcard dark:bg-gray-800 dark:border-gray-700 py-5 relative mt-[10%]">
                                <div className=" text-left px-5">
                                    <h5 className="text-tprimary font-bold mt-[5%] mb-[5%]">Social</h5>
                                    <ul className="m-0 p-0">
                                        {this.fb.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaFacebookF className="text-ssmblue cursor-pointer" size="18" onClick={(e) => this.props.openInNewTab(this.fb[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.fb[0].link}</div>
                                        </li> : <></>}
                                        {this.insta.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaSquareInstagram className="text-ssmpink cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.insta[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.insta[0].link}</div>
                                        </li> : <></> }
                                        {this.lkin.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaLinkedinIn className="text-ssmdrkblue cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.lkin[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.lkin[0].link}</div>
                                        </li> : <></> }
                                        {this.twit.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaSquareXTwitter className="text-tprimary cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.twit[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.twit[0].link}</div>
                                        </li> : <></> }
                                        {this.yt.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaYoutube className="text-ssmred cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.yt[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.yt[0].link}</div>
                                        </li> : <></> }
                                        {this.web.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaGlobe className="text-ssmltblue cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.web[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.web[0].link}</div>
                                        </li> : <></> }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 text-left text-tprimary  leading-7">
                            <ul className="flex flex-row items-center space-x-8 m-0 p-0">
                                <li className="flex flex-row space-x-4 mb-3 text-bgblue border-b-2 border-bgblue">
                                    <div className="col-span-1">
                                        <BiSolidUserDetail className="cursor-pointer" size="25"/>
                                    </div>
                                    <div className="col-span-7">Profile</div>
                                </li>

                                {!this.props.wm.connected ? <li className="flex flex-row space-x-4 mb-3">
                                    <input type="button" value="Connect" className="w-[100%] bg-bgblue hover:bg-bgbluehover text-center text-white text-sm font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline text-center cursor-pointer" onClick={(e)=> this.props.requestconnection(this.props.wm.membernumber)} />
                                </li> : <></>}
                                
                            </ul>
                            {this.props.wm.bio}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};

class Networking extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {workingmember: {}, viewprofile: false, filterval: '', runningfilter: false, keyword: '', filteroutcome: '', memberconnections: []};
        this.viewProfile = this.viewProfile.bind(this);
        this.openInNewTab = this.openInNewTab.bind(this);
        this.returntolist = this.returntolist.bind(this);
        this.getNetworkingMembers = this.getNetworkingMembers.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.runSearch = this.runSearch.bind(this);
        this.requestconnection = this.requestconnection.bind(this);
        this.setconnections = this.setconnections.bind(this);
        this.setenter = this.setenter.bind(this);
        this.setkeyword = this.setkeyword.bind(this);

        this.myRef = React.createRef();
        this.badges = {
            aa: {
                badge: '/assets/images/badges/digital_badge_aa.png'
            },
            bc: {
                badge: '/assets/images/badges/digital_badge_bc.png'
            },
            bcvip: {
                badge: '/assets/images/badges/digital_badge_bcvip.png'
            },
            vip: {
                badge: '/assets/images/badges/digital_badge_vip.png'
            }, 
            gpsvip: {
                badge: '/assets/images/badges/digital_badge_vip.png'
            }
        }
        let addprops = {
            viewProfile: this.viewProfile,
            openInNewTab: this.openInNewTab,
            returntolist: this.returntolist,
            requestconnection: this.requestconnection,
            badges: this.badges
        }
        this.allprops = {...props, ...addprops};
        if (this.props.portalmemnum) {
            
            this.getMemberConnections();
        } else {
            
            if (this.props.cookies.gpsuser) {
     
                let userInfo = JSON.parse(JSON.stringify(this.props.cookies.gpsuser));
               
                if (userInfo.p) {
                    
                    this.props.setportalmemnum(parseInt(userInfo.p));
                    setTimeout(() => {
                        this.getMemberConnections();
                    }, 2000);
                   
                }
            }
        }
        document.body.addEventListener('keydown', this.setenter );
    }

    applyFilter () {
        let filterOption = this.state.filterval;
        this.setState({filteroutcome: ''});
        this.props.setGatheringNetworkItems(true);
        switch (filterOption) {
            case "connections":
                const afterdbprocess = (nmems) => {
                    let outcome = '';
                    if (nmems && nmems.nwms) {
                        let networkInfo = decodeToken(nmems.nwms);
                        if (networkInfo.nms && !networkInfo.nms.length) {
                            outcome = 'No connections have been made.';
                        }
                        this.setState({runningfilter: true, filteroutcome: outcome, keyword: '', filterval: ''});
                        
                        if (this.state.memberconnections.length) {
                            this.setconnections(networkInfo.nms, this.state.memberconnections);
                        } else {
                            this.props.setnetworkitems(networkInfo.nms);
                        }
                    } 
                    
                    this.props.setGatheringNetworkItems(false);
                    
                };
                let url = variables.buProcessMemberService + "/api/memberconnections";
                
                let memInfo = {
                    membernumber: this.props.portalmemnum
                };
                rests.processrest (url, afterdbprocess, 'post', memInfo);
            break;
            default:
                this.setState({keyword: filterOption});

                setTimeout(() => {
                    this.runSearch('partner');
                }, 2000);
                
                break;
        }
        
    }

    getMemberConnections () {
        const afterdbprocess = (nmems) => {
            //let outcome = '';
            if (nmems && nmems.nwms) {
                let networkInfo = decodeToken(nmems.nwms);
                if (networkInfo.nms && !networkInfo.nms.length) {
                    //outcome = 'No connections have been made.';
                }
                
                this.setState({memberconnections: networkInfo.nms});
                this.setconnections(this.props.networkitems, networkInfo.nms);
            } 
            
            this.props.setGatheringNetworkItems(false);
            
        };
        let url = variables.buProcessMemberService + "/api/memberconnections";
        
        let memInfo = {
            membernumber: this.props.portalmemnum
        };
        
        rests.processrest (url, afterdbprocess, 'post', memInfo);
    }

    getNetworkingMembers () {
        this.props.setGatheringNetworkItems(true);
        const afternetmems = (nmems) => {
            
          if (nmems && nmems.nwms) {
            this.setState({filterval: '', runningfilter: false, keyword: '', filteroutcome: ''});
            let networkInfo = decodeToken(nmems.nwms);
            if (this.state.memberconnections.length) {
                this.setconnections(networkInfo.nms, this.state.memberconnections);
            }
           
            this.props.setnetworkitems(networkInfo.nms);
            this.props.setGatheringNetworkItems(false);
          }
        }
        let url = variables.buProcessMemberService + '/api/netmems';
        rests.processrest (url, afternetmems, 'get', {});
      }

    handleFilterChange (e) {
        let value = e.target.value;
        this.setState({filterval: value, runningfilter: value ? false : true});
    }

    handleSearchChange (e) {
        let value = e.target.value;
        this.setState({keyword: value});
    }

    resetFilter () {
        this.props.setnetworkitems([]);
        this.getNetworkingMembers();
    }

    requestconnection (askID) {
        let pc = Object.assign([], this.props.networkitems);
        let filternm = pc.filter(n=> n.membernumber === askID);
        const connectindicate = (ct) => {
            filternm[0].connecting = ct ? false : true;
            filternm[0].connectiontxt = ct;
            
            this.props.setnetworkitems(pc);
            if (document.getElementById('connection')) {
                
                document.getElementById('connection').innerHTML = ct;
            }
        }
        if (filternm.length) {
            connectindicate('Processing Connection...')
        }

        

        const afterdbprocess = (askInfo) => {
            connectindicate('Connection has been sent')
            setTimeout(() => {
                connectindicate('')
           }, 8000);
        };
        let url = variables.buProcessMemberService + "/api/requestconnection";
        let requestInfo = {
            reqmemnum: this.props.portalmemnum,
            askmemnum: askID
        }
        rests.processrest (url, afterdbprocess, 'post', requestInfo);
        
    }

    runSearch (stype) {
        this.setState({runningfilter: true, filteroutcome: ''});
        this.props.setGatheringNetworkItems(true);
        this.props.setnetworkitems([]);
        const afterdbprocess = (nmems) => {
            if (nmems && nmems.nwms) {
                let networkInfo = decodeToken(nmems.nwms);
                this.setState({runningfilter: true, filteroutcome: '', keyword: '', filterval: ''});
                if (this.state.memberconnections.length) {
                    this.setconnections(networkInfo.nms, this.state.memberconnections);
                } else {
                    this.props.setnetworkitems(networkInfo.nms);
                }
            }
            this.props.setGatheringNetworkItems(false);
        };
        let url = variables.buProcessMemberService + "/api/searchnetwork";
        let memInfo = {
            kw: this.state.keyword,
            type: stype
        };
        rests.processrest (url, afterdbprocess, 'post', memInfo);
    }

    setconnections (nms, connections) {
        let nwmems = Object.assign([], nms); 
        
        if (nms.length) {   
            for (let nc in connections) {
                let ncObj = connections[nc];
                let filtermembenc = nwmems.filter(m=> m.membernumber === ncObj.membernumber);
                if (filtermembenc.length) {
                    filtermembenc[0].connected = ncObj.connectionaccepted ? 1 : 0;
                    filtermembenc[0].requested = ncObj.connectionrequested ? 1 : 0;
                }
            }
        } else {
            for (let nw in nwmems) {
                nwmems[nw].connected = 0;
                nwmems[nw].requested = 0;
            }
        }
        if (nms.length) { 
            this.props.setnetworkitems(nwmems);
        }
    }

    setenter (e) {
        if (e.key === 'Enter' && this.state.keyword) {
            this.setState({runningfilter: true, filteroutcome: ''});
            this.runSearch('keyword');
        }
    }

    setkeyword(e) {
        let kw = e.target.value;
        this.setState({keyword: kw});
    }

    
    openInNewTab (url) {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    returntolist() {
        this.setState({workingmember: {}, viewprofile: false})
    }

    viewProfile (itemID) {
        let filtermember = this.props.networkitems.filter(n=> n.Id === itemID);
        
        if (filtermember.length) {
            this.myRef.current.scrollTo(0, 0);  
            this.setState({workingmember: filtermember[0], viewprofile: true})
        }
    }

    render() {

        return (
            <>
                 <div ref={this.myRef}></div> 
                {!this.state.viewprofile ? <section className="bg-white mt-[4%] pb-30 mb-[4%]">
                    <div className="grid grid-cols-12 gap-5 ">
                        <div className="col-span-12 pt-20">
                            <div className="container">
                                <div id="header-networking-container">
                                <div className="flex justify-center max-w-lg flex-col mx-auto mb-10">
                                    <p className="text-tsecondary leading-6 text-center">
                                    NETWORKING
                                    </p>
                                </div>
                                <h1 className="text-tprimary font-bold mb-10">Great connections is the key</h1>
                                <div className="flex justify-center max-w-lg flex-col mx-auto">
                                    <p className="text-tsecondary leading-6 text-center">
                                    This networking page is an opportunity for GPS Members to connect and get to know other GPS Members.
                                    </p>
                                </div>
                                <div id="search-bar-networking" className="grid grid-cols-4 md:grid-cols-4 gap-4">
                                    <div className="relative mb-2 col-span-3">
                                        <svg className="absolute left-2 top-4 block h-5 w-5 text-gray-400 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" onClick={(e)=> this.runSearch('keyword')}>
                                            <circle cx="11" cy="11" r="8" className=""></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65" className=""></line>
                                        </svg>
                                        <input type="text" id="keyword" name="keyword" className="block rounded pr-40 pl-10 pb-2.5 py-3 w-full text-sm text-gray-900 border border-b-2 border-gray-300 appearance-none border-gray-600 focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer" placeholder="Search" defaultValue={this.state.keyword} onBlur={(e) => this.handleSearchChange(e)} onKeyUp={(e) => this.setkeyword(e)}/>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="flex flex-row space-x-2">
                                            <div className="w-3/4">
                                                <select id="state" name="state" className="block rounded  pl-5 pb-2.5 py-3 w-full text-sm text-gray-900 border border-b-2 border-gray-300 appearance-none border-gray-600 focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer" placeholder="Filter" value={this.state.filterval} onChange={(e)=> this.handleFilterChange(e)} >
                                                    <option value="">Filter</option>
                                                    {this.props.portalmemnum ? <option value="connections">My Connections</option> : <></>}
                                                    <option value="AA">AA</option>
                                                    <option value="BC">BC</option>
                                                    <option value="BCVIP">BCVIP</option>
                                                    <option value="GPSVIP">GPSVIP</option>
                                                </select>
                                            </div>
                                            <div className="col-span-5">
                                                {!this.state.runningfilter ? <input type="button" value="Apply" className="w-[100%] bg-bgblue hover:bg-bgbluehover text-center text-white text-sm font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline text-center cursor-pointer" onClick={(e)=> this.applyFilter()}/> : <input type="button" value="Clear Filter" className="w-[100%] bg-bgblue hover:bg-bgbluehover text-center text-white text-sm font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline text-center cursor-pointer" onClick={(e)=> this.resetFilter()}/> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                
                                <div id="member-networking-container" className={`grid gap-4 ${this.props.isMobile ? 'grid-cols-2' : 'grid-cols-4'}`}>
                                    {this.props.networkitems && this.props.networkitems.length ? this.props.networkitems.map((v) => {
                                       if (v.showprofile) {
                                            return (
                                                <div key={Math.random()} className="w-full max-w-full  bg-white ">
                                                    <MemberBlock {...this.allprops} tm={v} ssms={v.ssms ? JSON.parse(v.ssms) : []}/>
                                                </div>
                                            )
                                        } else {
                                            return (<></>)
                                        }
                                    }) : this.props.gatheringnetworkitems ? <div className="col-span-4 text-left pt-10"><h2>We are gathering the networking list... Please wait...</h2></div> : <div className="col-span-4 text-left pt-10"><h2>
                                        {this.filteroutcome ? <span>{this.filteroutcome}</span> : <span>There are currently no items to list.</span>}
                                        
                                    </h2></div>}
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </section> : <NetworkingPage {...this.allprops} wm={this.state.workingmember} /> }
                <Connect {...this.props} bgblue={true}/>
            </>
        )
    }
}

export default Networking;