import React from "react";
import variables from "./rests/variables";
import Rests from "./rests/services";
import { decodeToken } from "react-jwt";

//import SharedFunctions from "./classes/sharedfunctions";
const rests = new Rests();
//const shared = new SharedFunctions();

const ForgotPasswordStep1 = (props) => {
    return (
        <>
            <div className="mt-20 max-w-[480px] text-center">
                <div className="text-center w-full mb-10">
                    <img className="max-w-[70px] mt-0 mx-auto" src="/assets/images/ic-password.png" alt="GPS Membership Portal"/>
                </div>
                <h3 className="text-tprimary font-semibold mb-4">Forgot your member number?</h3>
                <p className="text-tsecondary leading-6 text-justify">
                    Please enter the contact information associated with your account, and we'll email you information for your login.
                </p>
                
            </div>
            <div className="w-full text-left">
                <form id="gpslogin" className="bg-white pt-6 pb-8 mb-4" action='' method="post" onSubmit={(e) => props.handleForgot1Submit(e)}>
                    <div className="relative mb-4">
                        <input type="text" id="forgotemail" className="block rounded px-3 pb-2.5 pt-3 w-full text-sm text-gray-900 border border-b-2 border-gray-300 appearance-none border-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={props.forgotemail} onChange={props.handleChange} required/>
                        <label htmlFor="forgotemail" className="absolute bg-white text-sm text-ltgray text-700 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email address<span className="text-danger">*</span></label>
                    </div>
                    <div className="relative mb-4">
                        <input type="text" id="firstname" className="block rounded px-3 pb-2.5 pt-3 w-full text-sm text-gray-900 border border-b-2 border-gray-300 appearance-none border-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={props.firstname} onChange={props.handleChange}/>
                        <label htmlFor="firstname" className="absolute bg-white text-sm text-ltgray text-700 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">First Name</label>
                    </div>
                    <div className="relative mb-4">
                        <input type="text" id="lastname" className="block rounded px-3 pb-2.5 pt-3 w-full text-sm text-gray-900 border border-b-2 border-gray-300 appearance-none border-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={props.lastname} onChange={props.handleChange}/>
                        <label htmlFor="lastname" className="absolute bg-white text-sm text-ltgray text-700 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Last Name</label>
                    </div>
                    <div className="relative mb-4">
                        <input type="text" id="zip" className="block rounded px-3 pb-2.5 pt-3 w-full text-sm text-gray-900 border border-b-2 border-gray-300 appearance-none border-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={props.zip} onChange={props.handleChange}/>
                        <label htmlFor="zip" className="absolute bg-white text-sm text-ltgray text-700 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Zip Code</label>
                    </div>
                    <div className="flex items-center justify-between">
                        <input type="submit" value="Send Request" className="w-full bg-bgblue hover:bg-bgbluehover text-center text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" />
                    </div>
                </form>

                <div className="text-center mb-3">
                    <a className="align-baseline text-sm text-tprimary text-400 underline hover:text-tanchor" href="/" onClick={(e)=> props.handleFormView(e, 'login')}>
                        Return to sign in
                    </a>
                </div>
            </div>
        </>
    )
}

const ForgotPasswordStep2 = (props) => {

    return (
        <>
            <div className="mt-20 max-w-[480px] text-center">
                <div className="text-center w-full mb-10">
                    <img className="max-w-[70px] mt-0 mx-auto" src="/assets/images/ic-email-inbox.png" alt="GPS Membership Portal"/>
                </div>
                <h3 className="text-tprimary font-semibold mb-4">Please check your email!</h3>
                <p className="text-tsecondary leading-6 text-center">
                    Please check your inbox for information regarding your login information.
                </p>
                
            </div>
            <div className="text-center mb-3">
                <a className="align-baseline text-sm text-tprimary text-400 underline hover:text-tanchor" href="/" onClick={(e)=> props.handleFormView(e, 'login')}>
                    Return to sign in
                </a>
            </div>
        </>
    )
}

const LoginForm = (props) => {
   
    return (
        <>
            <div className="mt-20 max-w-[480px] text-left">
                <h4 className="text-tprimary font-semibold">Please sign in below</h4>
            </div>
            <div className="w-full text-left">
                <form id="gpslogin" className="bg-white pt-6 pb-8 mb-4" action='' method="post" onSubmit={(e) => props.handleSubmit(e)}>
                    
                    <div className="relative mb-4">
                        <input type="text" id="username" className="block rounded px-3 pb-2.5 pt-3 w-full text-sm text-gray-900 border border-b-2 border-gray-300 appearance-none border-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={props.username} onChange={props.handleChange}/>
                        <label htmlFor="username" className="absolute bg-white text-sm text-ltgray text-700 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email address</label>
                    </div>

                    <div className="relative mb-6">
                        <input type="password" id="password" className="block rounded px-3 pb-2.5 pt-3 w-full text-sm text-gray-900 border border-b-2 border-gray-300 appearance-none border-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={props.password} onChange={props.handleChange}/>
                        <label htmlFor="password" className="absolute bg-white text-sm text-ltgray text-700 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Member number</label>
                    </div>
                    
                    <div className="text-right mb-3">
                        <a className="align-baseline text-sm text-tprimary text-400 underline hover:text-tanchor" href="/" onClick={(e)=> props.handleFormView(e, 'forgot1')}>
                            Forgot Your Member Number?
                        </a>
                    </div>
                    <div className="flex items-center justify-between">
                        <input type="submit" value="Login" className="w-full bg-bgblue hover:bg-bgbluehover text-left text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" />
                    </div>
                </form>
            </div>
        </>
    )
}
class Login extends React.Component {
    //can use ahigdon51@gmail.com - 221695076 to login
    constructor(props) {
        super(props);
        this.state = {username: '', password: '', firstname: '', lastname: '', zip: '', formview: 'login', forgotemail: '', forgoterror: '', showprogress: false, showerror: false, errmsg: ''};
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormView = this.handleFormView.bind(this);
        this.handleForgot1Submit = this.handleForgot1Submit.bind(this);

        let addprops = {
            handleChange: this.handleChange,
            handleSubmit: this.handleSubmit,
            handleFormView: this.handleFormView,
            handleForgot1Submit: this.handleForgot1Submit,
            username: this.state.username ? this.state.username : '',
            password: this.state.password ? this.state.password : '',
            forgotemail: this.state.forgotemail ? this.state.forgotemail : '',
            forgoterror: this.state.forgoterror ? this.state.forgoterror : '',
            firstname: this.state.firstname ? this.state.firstname : '',
            lastname: this.state.lastname ? this.state.lastname : '',
            zip: this.state.zip ? this.state.zip : ''
        }
        
        this.allprops = {...this.props, ...addprops}
        /*this.props.tagManagerArgs = {
            dataLayer: {
                event: 'gpslogin',
                pagePath: '/login',
                pageTitle: 'GPS Login'
            },
            dataLayerName: 'PageDataLayer'
        }*/

        
        
    }

    handleFormView(e, viewtype) {
        e.preventDefault();
        this.setState({ formview: viewtype, forgoterror: '', showprogress: false});
    }
    
    handleChange(event) {
        let fld = event.target.id;
        this.setState({[fld]: event.target.value});
    }

    handleForgot1Submit(event) {
        event.preventDefault();
        this.setState({forgoterror: '', showprogress: true});
        
        const aftermember = (memInfo) => {
           
            if (memInfo) {
                let loginInfo = decodeToken(memInfo);
                
                let approvedcodes = ['GPSVIP','BCVIP','BC','AA']
                //sendwebmail
                if (loginInfo.donoraccount) {
                    const sendmail = (loginInfo) => {
                        const emailInfo = {
                            "subject": "GPS Membership Portal Information", 
                            "template": "custom",
                            "emailto": this.state.forgotemail,
                            "variables": {
                                headerlink: "https://www.gpspartner.org",
                                receiptheader:"https://payments.tdjakes.org/wp-content/uploads/2024/06/gps_email_header.jpg",
                                emailheader: "GPS Membership Portal Password Information",
                                message: '<p>Please see your GPS Membership Portal Information</p><p>Email: ' + this.state.forgotemail + ' <br>Member Number: ' + loginInfo.donoraccount,
                                headline: "",
                                footerlinks: ''
                            }
                        }
                        const afteremailsent = (em) => {
                            
                            this.setState({formview: 'forgot2', forgoterror: '', showprogress: false});
                        }
                        let url = variables.buProcessService + '/sendwebmail';
                        rests.processrest (url, afteremailsent, 'post', emailInfo);
                    };

                    if (approvedcodes.includes(loginInfo.partner)) {
                        sendmail(loginInfo);
                    } else {
                        let filtertype = loginInfo.typeInfo && loginInfo.typeInfo.length && loginInfo.typeInfo[0].items.length ? loginInfo.typeInfo[0].items.filter(t=> t.type === 'GPSLEVEL') : [];
                        if (filtertype.length) {
                            loginInfo.partner = filtertype[0].value;
                            loginInfo.partnerdesc = filtertype[0].valueDescription;
                            if (approvedcodes.includes(filtertype[0].value)) {
                                sendmail(loginInfo);
                            } else {
                                this.setState({forgoterror: 'We were unable to find verify you as an active GPS Member.  Please try again or call 1-800-Bishop2', showprogress: false})
                            }
                        } else {
                            this.setState({forgoterror: 'We were unable to find verify you as an active GPS Member.  Please try again or call 1-800-Bishop2', showprogress: false})
                        }
                    }
                } else {
                    this.setState({forgoterror: 'We were unable to find your membership information.  Please try again or call 1-800-Bishop2', showprogress: false});
                }
            }
            
        }
        
        if (this.state.forgotemail) {

            let searchData = {
            customeremail: this.state.forgotemail,
            email: this.state.forgotemail,
            firstName: this.state.firstname,
            lastName: this.state.lastname,
            zipCode: this.state.zip,
            conferenceID: 0,
            donoraccount: 0,
            partner: "NA",
            partnerdesc: "",
            gender: "",
            age: "",
            address: [],
            phone: [],
            membershipInfo: [],
            typeInfo: [],
            emailInfo: [],
            donortoken: "",
            levels: "GSPVIP,BCVIP,BC,AA",
            trybyemail: 1
            };
            let url = variables.buProcessService + '/api/searchdonor';
            const jwtSecretKey = variables.jwtscr;
            const token = rests.createjwt(searchData, jwtSecretKey);
            let searchInfo = {
                searchjwt: token
            }
            
            rests.processrest (url, aftermember, 'post', searchInfo);
        } else {
            this.setState({forgoterror: 'Your email address is required.', showprogress: false});
        }
    }
    
    handleSubmit(event) {
        event.preventDefault();
        //let approvedcodes = ['GPSVIP','BCVIP','BC','AA']
        this.setState({showprogress: true, showerror: false, errmsg: ''});
        /*const afterlogin = (memberInfo) => {
            
            let loginInfo = decodeToken(memberInfo);
            
            if (loginInfo && parseInt(loginInfo.donoraccount)) {
                const openmember = (partner, partnerdesc) => {
                    //console.log('approved partner')
                    this.props.setlogin(true);
                    let gpsuser = {u: this.state.username, p:this.state.password };
                    this.props.setcookie('gpsuser', JSON.stringify(gpsuser), { path: '/' })
                    this.props.setuserinfo(memberInfo);
                    this.setState({showprogress: false});

                    let mInfo = {
                        process: 'donorupdate',
                        donoraccount: loginInfo.donoraccount,
                        firstname: loginInfo.firstName,
                        lastname: loginInfo.lastName,
                        email: loginInfo.email,
                        partner: partner,
                        partnerdesc: partnerdesc,
                        address1: loginInfo.address && loginInfo.address.line1 ? loginInfo.address.line1 : '',
                        address2: loginInfo.address && loginInfo.address.line2 ? loginInfo.address.line2 : '',
                        city: loginInfo.address && loginInfo.address.city ? loginInfo.address.city : '',
                        state: loginInfo.address && loginInfo.address.state ? loginInfo.address.state : '',
                        zip: loginInfo.address && loginInfo.address.postalCode ? loginInfo.address.postalCode : '',
                        country: loginInfo.address && loginInfo.address.country ? loginInfo.address.country : '',
                        phone: loginInfo.phone && loginInfo.phone.phoneNumber ? loginInfo.phone.phoneNumber : '',
                        addressID: loginInfo.address && loginInfo.address.id ? loginInfo.address.id : 0,
                        phoneID: loginInfo.phone && loginInfo.phone.id ? loginInfo.phone.id : 0,
                        emailID: loginInfo.emailInfo && loginInfo.emailInfo.id ? loginInfo.emailInfo.id : 0
                    }

                    let url = variables.buProcessMemberService + '/api/members/processportalmember';
                    
                    const aftersetinfo = (mInfo) => {
                        console.log(mInfo)
                        console.log(loginInfo);
                        
                       /* this.props.setItems(mObj.memberdonorInfo.transactions);
                        let partnercode = mObj.workingmember.partner ? mObj.workingmember.partner.toLowerCase() : '';
                        let badgeImg = partnercode && this.badges[partnercode] ? this.badges[partnercode].badge : '';
                        let badgeStyle = {
                            backgroundImage:
                                "url('" + badgeImg + "')",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            paddingBottom: "30%"
                        };
                        setTimeout(() => {
                            let pageInfo = shared.CalcPage(1, this.props);
                            this.setState({memberdonorInfo: mObj.memberdonorInfo, workingmember: mObj.workingmember, pageInfo: pageInfo, badgeImg: badgeImg, badgeStyle: badgeStyle});
                            this.props.setListItems(pageInfo.data);
                        }, 3000);
                    }
                    const jwtSecretKey = variables.jwtscr;
                    const token = rests.createjwt(mInfo, jwtSecretKey);
                    let memInfo = {
                        memjwt: token
                    }

                    //Here we send to the gps database to capture and update information that comes in from donor
                    rests.processrest (url, aftersetinfo, 'post', memInfo);
                }
                if (approvedcodes.includes(loginInfo.partner)) {
                    openmember(loginInfo.partner, loginInfo.partnerdesc);
                } else {
                    let filtertype = loginInfo.typeInfo && loginInfo.typeInfo.length && loginInfo.typeInfo[0].items.length ? loginInfo.typeInfo[0].items.filter(t=> t.type == 'GPSLEVEL') : [];
                    if (filtertype.length) {
                        
                        if (approvedcodes.includes(filtertype[0].value)) {
                            openmember(filtertype[0].value, filtertype[0].valueDescription);
                           
                        } else {
                            console.log('not approved to login')
                            this.setState({showprogress: false, showerror: true});
                        }
                    } else {
                        console.log('not approved to login')
                        this.setState({showprogress: false, showerror: true});
                    }
                }
            }
            
        }*/
        /*var searchData = {
			customeremail: this.state.username ,
			email: this.state.username,
			firstName: '',
			lastName: '',
			zipCode: "",
			conferenceID: 0,
			donoraccount: this.state.password,
			partner: "NA",
			partnerdesc: "",
			gender: "",
			age: "",
			address: [],
			phone: [],
			membershipInfo: [],
			typeInfo: [],
			emailInfo: [],
			donortoken: "",
			levels: "GSPVIP,BCVIP,BC,AA",
            trybyemail: 1,
            gettransactions: 1,
            transactions: []
		};*/
       // let url = variables.buProcessService + '/api/searchdonor';
       // const jwtSecretKey = variables.jwtscr;
       // const token = rests.createjwt(searchData, jwtSecretKey);
      /*  let searchInfo = {
            searchjwt: token
        }*/

        const updatefromdonor = (loginInfo, partner, partnerdesc) => {
            let mInfo = {
                process: 'donorupdate',
                donoraccount: loginInfo.donoraccount,
                firstname: loginInfo.firstName,
                lastname: loginInfo.lastName,
                email: loginInfo.email,
                partner: partner,
                partnerdesc: partnerdesc,
                address1: loginInfo.address && loginInfo.address.line1 ? loginInfo.address.line1 : '',
                address2: loginInfo.address && loginInfo.address.line2 ? loginInfo.address.line2 : '',
                city: loginInfo.address && loginInfo.address.city ? loginInfo.address.city : '',
                state: loginInfo.address && loginInfo.address.state ? loginInfo.address.state : '',
                zip: loginInfo.address && loginInfo.address.postalCode ? loginInfo.address.postalCode : '',
                country: loginInfo.address && loginInfo.address.country ? loginInfo.address.country : '',
                phone: loginInfo.phone && loginInfo.phone.phoneNumber ? loginInfo.phone.phoneNumber : '',
                addressID: loginInfo.address && loginInfo.address.id ? loginInfo.address.id : 0,
                phoneID: loginInfo.phone && loginInfo.phone.id ? loginInfo.phone.id : 0,
                emailID: loginInfo.emailInfo && loginInfo.emailInfo.id ? loginInfo.emailInfo.id : 0
            }

            let url = variables.buProcessMemberService + '/api/members/processportalmember';
            
            const aftersetinfo = (mInfo) => {
                
            }
            const jwtSecretKey = variables.jwtscr;
            const token = rests.createjwt(mInfo, jwtSecretKey);
            let memInfo = {
                memjwt: token
            }

            //Here we send to the gps database to capture and update information that comes in from donor
            rests.processrest (url, aftersetinfo, 'post', memInfo);
        }

        const openmember = (mObj) => {
            
            this.props.setuserinfo(mObj.memberdonorInfo);
            this.props.setworkingmember(mObj.workingmember);
            this.props.setItems(mObj.memberdonorInfo.transactions);
            let gpsuser = {u: this.state.username, p:this.state.password };
            this.props.setcookie('gpsuser', JSON.stringify(gpsuser), { path: '/' })
            this.props.setlogin(true);
            this.props.setportalmemnum(parseInt(mObj.memberdonorInfo.donoraccount));
            updatefromdonor(mObj.memberdonorInfo, mObj.memberdonorInfo.partner, mObj.memberdonorInfo.partnerdesc);
            
        }

        const aftergetmember = (mObj) => {
            
            if (mObj.memberdonorInfo && mObj.memberdonorInfo.emailInfo && mObj.memberdonorInfo.emailInfo.emailAddress) {
                if (mObj.memberdonorInfo.emailInfo.emailAddress.toLowerCase().trim() !== this.state.username.toLowerCase().trim()) {
                    this.setState({showprogress: false, showerror: true, errmsg: "We're sorry, we were unable to verify your GPS membership. Please call 1-800-247-4672 and select option 1 for assistance."});
                } else {
                    openmember(mObj);
                }
            } else if (mObj.memberdonorInfo && !Object.keys(mObj.memberdonorInfo).length) {
                this.setState({showprogress: false, showerror: true, errmsg: "We're sorry, we were unable to verify your GPS membership. Please call 1-800-247-4672 and select option 1 for assistance."});
            } else {
                openmember(mObj);
            }

        }
        rests.getmember (this.state.username, this.state.password, aftergetmember)
    }

    

    render() {
        return (
            <>
                <section>
                    <div className="grid grid-cols-12 gap-5">
                        <div id="login-container" className={`p-20 ${this.props.isMobile ? 'col-span-12' : 'col-span-5'}`}>
                            <a href="/">
                                <img className="max-w-[280px]" src="https://gpspartner.org/wp-content/uploads/sites/6/2017/09/gpslogo.png" alt="GPS Membership Portal"/>
                            </a>
                            {this.state.formview === 'login' ? <LoginForm {...this.allprops} /> : <></>}
                            {this.state.formview === 'forgot1' ? <ForgotPasswordStep1 {...this.allprops} /> : <></>}
                            {this.state.formview === 'forgot2' ? <ForgotPasswordStep2 {...this.allprops} /> : <></>}
                            {this.state.showprogress ? <p className="text-success font-bold">Processing Request Please Wait...</p> : <></>}
                            {this.state.showerror? <p className="text-danger font-bold">{this.state.errmsg ? this.state.errmsg : "We're sorry, we were unable to verify your GPS membership.  Please try again"}</p>: <></>}
                            {this.state.forgoterror ? <p className="text-danger font-bold">{this.state.forgoterror}</p> : <></>}
                            <p><a href="https://www.gpspartner.org/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> | <a href="https://www.gpspartner.org/terms-of-use/" target="_blank" rel="noreferrer">Terms Of Use</a></p>
                        </div>
                        <div className={` p-4 ${this.props.isMobile ? 'hidden' : 'col-span-7'}`}>
                            <img src="https://www.gpspartner.org/wp-content/uploads/2024/06/Phill2-35-1030x748.jpg" alt="GPS Membership Portal"/>
                        </div>
                    </div>
                </section>
            </>
        )
    }
};

export default Login;