const variables = {
    activeoptions: [
		{id: '1', name: 'Active', idvalue: '1', value: 'Active'},
		{id: '0', name: 'Inactive', idvalue: '0', value: 'Inactive'}
	],
	ampm: [
		{id: 'am', name: 'am'},
		{id: 'pm', name: 'pm'}
	],
	bk: 'NzI1ZTk5NzIyZDJmMTRlOGY1NWFkNWJiMGIzOTE5MTA=',
	buProcessService: process.env.NODE_ENV === "production" ? 'https://bvtv.tdjpartners.com' : 'http://localhost:3000',
	buProcessMemberService: process.env.NODE_ENV === "production" ? 'https://payment.tdjakes.org' : 'http://localhost:8000',
	buLocalProcessService: 'http://localhost:3000',
	db: 'http://tphvendorapi.azurewebsites.net',
	hours: [
		{id: '01', name: '01'},
		{id: '02', name: '02'},
		{id: '03', name: '03'},
		{id: '04', name: '04'},
		{id: '05', name: '05'},
		{id: '06', name: '06'},
		{id: '07', name: '07'},
		{id: '08', name: '08'},
		{id: '09', name: '09'},
		{id: '10', name: '10'},
		{id: '11', name: '11'},
		{id: '12', name: '12'}
	],
	jwtscr: '2182312c81187ab82bbe053df6b7aa55',
	minutes: [
		{id: '00', name: '00'},
		{id: '05', name: '05'},
		{id: '10', name: '10'},
		{id: '15', name: '15'},
		{id: '20', name: '20'},
		{id: '25', name: '25'},
		{id: '30', name: '30'},
		{id: '35', name: '35'},
		{id: '40', name: '40'},
		{id: '45', name: '45'},
		{id: '50', name: '50'},
		{id: '55', name: '55'}
	],
	paymentProcessService: 'https://payment.tdjakes.org',
	stagingMode: 0,
	smwk: 'MTYzNzMwNjcyNzc5MDAxMTgy',
	smauthID: 'Y2JlYzAyM2UtYzcwOC04NWRiLTdmN2UtZjY0N2QzZDUyODNm',
	smauthtoken: 'QUtRZTRMUTRPU1hhbFZNeDlpUWE=',
    ynoptions: [
		{id: '1', name: 'Yes'},
		{id: '0', name: 'No'}
	]
}

export default variables;

