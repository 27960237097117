import React, { useState, useEffect } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import axios from 'axios'



const UpcomingEvents = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [eventData, setEventData] = useState([]);

    useEffect(() => {
        axios.get("https://www.gpspartner.org/wp-json/gpsdata/v1/events", {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_PHOTO_BEARER}}`,
            },
          })
          .then((res) => {
            setEventData(res.data);
          })
          .catch((err) => {
            console.log(err);
          })
    },[])

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      };

      const onClickPrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } 
        return
      };
      const onClickNext = () => {

        if (currentIndex < eventData.length - 3) {
            setCurrentIndex(currentIndex + 1);
        } 
        return
      };

      const getDate = (eventDate, endDate) => {
        const allMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        // const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", ]
        const startDate = new Date(eventDate);
        const startday = startDate.toLocaleDateString().split("/")[1];
        const endEventDate = new Date(endDate);
        const endDay = endEventDate.toLocaleDateString().split("/")[1];
        const month = allMonths[startDate.getMonth()];
        const year = startDate.getFullYear();


        return month + " " + startday + "-" + endDay + ", " + year

      };

      const replaceHTMLString = (string) => {
        const regex = /<[^>]*(>|$)|&nbsp;|&rdquo;|&rsquo;|&ldquo;|&zwnj;|&raquo;|&laquo;|&ndash;|&#8220;|&#8221;|&#8480;|&gt;/g;
        const result = string.replace(regex, "");
        return result;
      };

// TODO MAKE RESPONSIVE
    return (
        <div className="flex flex-col justify-center items-center relative">
            <div id="events" />
            <div className="w-full" style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            <h1 className="pl-48 mt-6 font-bold self-start">Upcoming Conferences and Events</h1>
            </div>
            <div id="rightArrow" className="flex justify-center items-center p-1 ml-8 cursor-pointer" onClick={() => onClickPrev()} style={currentIndex > 0 ? {userSelect: 'none', position: 'absolute', left: 0, borderColor: 'black', borderRadius: 30, borderStyle: 'solid', borderWidth: 3} : { userSelect: 'none',position: 'absolute', left: 0, borderColor: '#C4C4C4', borderRadius: 30, borderStyle: 'solid', borderWidth: 3}}>
            <AiOutlineLeft  style={currentIndex > 0 ? {color: 'black'} : {color: '#C4C4C4'}} size={40}/>
            </div>
            <div id="event-container" className={eventData.length <= 1 ? "flex justify-center w-1/3" : eventData.length === 2 ?  "flex m-10 gap-1 w-3/5 min-h-[650px]" : "flex m-10 gap-1 w-4/5 min-h-[650px]"}>
            {eventData.slice(currentIndex, currentIndex + 3).map((item, index) => {
                if (eventData.length <= 1) {
                    return (
                        <div key={index} className="w-full flex justify-center items-center p-4">
                            <div id="event-wall" className="w-full flex justify-center flex-col items-start text-left">
                            <img onClick={() => openInNewTab(item.link)} src={item.image} alt=""  style={{width: 600, height: 270, cursor: 'pointer'}}/>
                            <div className="mt-6 mb-6 flex items-center justify-center rounded-[40px]" style={{backgroundColor: '#E6EDF3'}}>
                            <p className="self-center text-sm text-bgblue pt-3 pb-0 pr-2 pl-2">{getDate(item.date, item.enddate)}</p>
                            </div>
                            <h2 className="font-semibold" >{item.title}</h2>
                            <p className="mt-6 text-left leading-5" style={{color: '#637381'}}>{replaceHTMLString(item.description)}</p>
                            <button className="bg-transparent flex flex-row justify-around items-center w-2/5 mt-3 border-1 rounded-xl p-2 font-bold" style={{borderColor: "#919EAB" }} onClick={() => openInNewTab(item.link)}>Register <MdOutlineKeyboardArrowRight size={30} /></button>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div key={index} className="w-full flex justify-center items-center p-4">
                            <div id="event-wall" className="w-full flex flex-col items-start text-left">
                            <img onClick={() => openInNewTab(item.link)}  src={item.image} alt="" className="" style={{width: 600, height: 270, cursor: 'pointer'}}/>
                            <div className="mt-6 mb-6 flex items-center justify-center rounded-[40px]" style={{backgroundColor: '#E6EDF3'}}>
                            <p className="self-center text-sm text-bgblue pt-3 pb-0 pr-2 pl-2">{getDate(item.date, item.enddate)}</p>
                            </div>
                            <h2 className="font-semibold" >{item.title}</h2>
                            <p className="mt-6 text-left leading-5" style={{color: '#637381', height: 100, maxHeight: 100, overflow: 'none'}}>{replaceHTMLString(item.description)}</p>
                            <button className="bg-transparent flex flex-row justify-around items-center w-2/5 mt-3 border-1 rounded-xl p-2 font-bold" style={{borderColor: "#919EAB" }} onClick={() => openInNewTab(item.link)}>Register <MdOutlineKeyboardArrowRight size={30} /></button>
                            </div>
                        </div>
                    )
                }
                
            })}
            
            </div>
            <div id="rightArrow" className="flex justify-center items-center p-1 mr-8 cursor-pointer" onClick={() => onClickNext()} style={currentIndex >= eventData.length - 3 ? {position: 'absolute', right: 0, borderColor: '#C4C4C4', borderRadius: 30, borderStyle: 'solid', borderWidth: 3, userSelect: 'none'} : {userSelect: 'none', position: 'absolute', right: 0, borderColor: 'black', borderRadius: 30, borderStyle: 'solid', borderWidth: 3}}>
            <AiOutlineRight  style={ currentIndex >= eventData.length - 3 ? {color: '#C4C4C4'}  : {color: 'black'}}  size={40}/>
            </div>
        </div>
    )
}

export default UpcomingEvents;