import React, { useState, useEffect } from "react";
import PhotoCarasel from "../molecule/PhotoCarasel";
import axios from "axios";

const PhotoGallery = (props) => {
  // Declare Varaibles
  const [photoCarasel, setPhotoCarasel] = useState(false); // State to control photo popup boolean
  const [photoData, setPhotoData] = useState();
  const [activeTab, setActiveTab] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [photo, setPhoto] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // useEffect to call photos API
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://www.gpspartner.org/wp-json/gpsdata/v1/gallery", {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PHOTO_BEARER}}`,
        },
      })
      .then((res) => {
        const photos = Object.keys(res.data).map((item) => {
          return {
            url: res.data[item].media,
            title: res.data[item].title,
            featuredID: res.data[item].featuredID,
          }
        });
        setPhoto(photos);
        setActiveTab(photos[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  
  // Function to move featured image to front of array
  const moveToFront = (data, matchingId) => {
    //find the index of the element in the array
    const index = data.findIndex(({id}) => id === matchingId);
    if(index !== -1) {
      //if the matching element is found, 
      const updatedData = [...data];
      //then remove that element and use `unshift`
      updatedData.unshift(...updatedData.splice(index, 1));
      return updatedData;
    }
    //if the matching element is not found, then return the same array
    return data;
  }
  // open photo carasel function
  const viewPhotoGallery = (data, index) => {
    if (index === 0) {
      setPhotoData(moveToFront(data.url, data.featuredID));
    } else {
      setPhotoData(data.url);
    }
    setPhotoCarasel(true);
    setPhotoIndex(index);
  };
  // close photo carasel function
  const closePhotoGallery = () => {
    setPhotoCarasel(false);
  };

  const onTabClick = (tab, index) => {
      setActiveTab(photo[index]);
      setActiveIndex(index);

  };
  
const filterFeaturedPhoto = (id) => {
  const featuredPhoto = activeTab?.url?.filter((item) => {
    return item.id === id
  });

  return featuredPhoto;
};

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="bg-neutral flex justify-center pb-[70px]">
      <div id="gallery" />
      {photoCarasel ? (
        <PhotoCarasel
          slides={photoData}
          index={photoIndex}
          closeClick={() => closePhotoGallery()}
        />
      ) : null}
      <div id="bigcontainer" className="w-3/4">
        <div
          id="title-text"
          className="flex flex-col justify-center items-start pt-[40px]"
        >
          <h1 className="text-tprimary font-bold">GPS Photo Galleries</h1>
          <p className="my-[25px]">
            Dive into a visual safari of our most thrilling and captivating
            moments captured in the wild
          </p>
        </div>
        <div className="flex flex-row mb-[40px] gap-[40px]" id="photoContainer">
          {photo?.map((item, index) => {
            return (
              <div
                id="buttonContainer"
                className={`${
                  index === activeIndex ? "bg-bgblue" : "bg-transparent"
                } border-bgblue border-2 border-solid p-[10px] flex min-w-2 xl:h- justify-center items-center cursor-pointer px-[20px] rounded-[30px]`}
                onClick={() => onTabClick(item, index)}
                key={index}
              >
                <h5
                  id="buttonTabs"
                  className={`self-center ${
                    index === activeIndex ? "text-white" : "text-bgblue"
                  } mt-1`}
                >
                  {item.title}
                </h5>
              </div>
            );
          })}
        </div>
        <div id="photo01" className="relative">
          <img
            onClick={() => viewPhotoGallery(activeTab, 0)}
            className="myImage border-none w-full h-[450px]"
            src={filterFeaturedPhoto(activeTab.featuredID)[0].url}
            alt=""
          />
          <h3 className="absolute bottom-4 left-4 text-white">
            {filterFeaturedPhoto(activeTab.featuredID)[0].title}
          </h3>
        </div>
        <div
          id="carsalContainer"
          className="flex justify-between content-start w-full mt-[50px]"
        >
          {activeTab.url
            .filter((item, index) => {
              return isMobile
                ? index >= 0 && index <= 3
                : index >= 1 && index <= 3;
            })
            .map((item, index) => {
              return (
                <div
                  key={index}
                  id="photoCarsal"
                  className="relative"
                  style={{ width: "30%" }}
                >
                  <img
                    onClick={() => viewPhotoGallery(activeTab, index + 1)}
                    key={index}
                    id="imageCarsal"
                    className="bottomImage border-none h-[500px] w-full"
                    src={item.url}
                    alt=""
                  />
                  <h3 className="absolute bottom-4 left-4 text-white">
                    {item.title}
                  </h3>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
