import React, { useState, useEffect }  from "react";
import { useParams } from 'react-router-dom';
import { FaRegUser } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaGlobe } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { decodeToken } from "react-jwt";
import { BiSolidUserDetail } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import variables from "../rests/variables";
import Rests from "../rests/services";
const rests = new Rests();

class NetworkingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        let partnercode = this.props.wm.partner ? this.props.wm.partner.toLowerCase() : '';
        this.badgeImg = partnercode && this.props.badges[partnercode] ? this.props.badges[partnercode].badge : '';
        this.badgeStyle = {
            backgroundImage:
                "url('" + this.badgeImg + "')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            paddingBottom: "30%"
        };
        let ssms = this.props.wm.ssms ? JSON.parse(this.props.wm.ssms ) : []
        this.fb = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'facebook') : [];
        this.insta = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'instagram') : [];
        this.lkin = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'linkedin') : [];
        this.twit = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'twitter') : [];
        this.yt = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'youtube') : [];
        this.web = ssms && ssms.length ? ssms.filter(sm=> sm.type === 'website') : [];
        
    }
    

    render () {
        return (
            <section className="bg-white mt-[4%] pb-30 mb-[4%]">
               
                <div className="container">
                    <div className="col-span-12 pt-20 pb-0 text-left" >
                        <h3 className="text-tprimary font-bold">{this.props.wm.firstname} {this.props.wm.lastname}</h3>
                        <div className="flex flex-row space-x-2">
                            <div className="text-tprimary text-sm cursor-pointer">Networks</div>
                            <div className="pt-[5px]">
                                <GoDotFill size={10}/>
                            </div>
                            <p className="text-ltgray text-sm">{this.props.wm.firstname} {this.props.wm.lastname}</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-5 mt-[3%]">
                        <div className="col-span-1 text-center">
                            <div className="w-full max-w-full  bg-white border border-gray-200 rounded-lg shadow-gcard dark:bg-gray-800 dark:border-gray-700 py-5 relative">
                                <div className="relative">
                                    {this.props.wm.photo && this.props.wm.photoapproved ? <img className="w-[80%]  m-auto rounded-circle border border-gray-200" src={this.props.wm.photo} alt="GPS Member"/> : <div className="relative bg-ltgray p-3 rounded-full w-128 h-128"> <FaRegUser className="w-24 h-24 rounded-circle text-white"/></div>}
                                    <div className="absolute z-10 w-[20%] bg-right left-[1%] bottom-0 " style={this.badgeStyle}></div>
                                </div>

                                <div className=" text-left px-5">
                                    <h5 className="text-tprimary font-bold mt-[5%]">Contacts</h5>
                                    <div className="flex flex-row space-x-5">
                                        <div className="col-span-1">
                                            <MdEmail size="20" className="text-tprimary text-sm"/>
                                        </div>
                                        <div className="col-span-7">{this.props.wm.email}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full max-w-full  bg-white border border-gray-200 rounded-lg shadow-gcard dark:bg-gray-800 dark:border-gray-700 py-5 relative mt-[10%]">
                                <div className=" text-left px-5">
                                    <h5 className="text-tprimary font-bold mt-[5%] mb-[5%]">Social</h5>
                                    <ul className="m-0 p-0">
                                        {this.fb.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaFacebookF className="text-ssmblue cursor-pointer" size="18" onClick={(e) => this.props.openInNewTab(this.fb[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.fb[0].link}</div>
                                        </li> : <></>}
                                        {this.insta.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaSquareInstagram className="text-ssmpink cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.insta[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.insta[0].link}</div>
                                        </li> : <></> }
                                        {this.lkin.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaLinkedinIn className="text-ssmdrkblue cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.lkin[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.lkin[0].link}</div>
                                        </li> : <></> }
                                        {this.twit.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaSquareXTwitter className="text-tprimary cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.twit[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.twit[0].link}</div>
                                        </li> : <></> }
                                        {this.yt.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaYoutube className="text-ssmred cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.yt[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.yt[0].link}</div>
                                        </li> : <></> }
                                        {this.web.length ? <li className="flex flex-row space-x-5 mb-3">
                                            <div className="col-span-1">
                                                <FaGlobe className="text-ssmltblue cursor-pointer" size="20" onClick={(e) => this.props.openInNewTab(this.web[0].link)}/>
                                            </div>
                                            <div className="col-span-7"> {this.web[0].link}</div>
                                        </li> : <></> }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 text-left text-tprimary  leading-7">
                            <ul className="flex flex-row items-center space-x-8 m-0 p-0">
                                <li className="flex flex-row space-x-4 mb-3 text-bgblue border-b-2 border-bgblue">
                                    <div className="col-span-1">
                                        <BiSolidUserDetail className="cursor-pointer" size="25"/>
                                    </div>
                                    <div className="col-span-7">Profile</div>
                                </li>
                            </ul>
                            {this.props.wm.bio}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};

const ViewProfile = (props) => {
    const { id } = useParams();
    const [wm, setProfile] = useState({});
    const [profilereceived, setProfileReceived] = useState(false);
    let badges = {
        aa: {
            badge: '/assets/images/badges/digital_badge_aa.png'
        },
        bc: {
            badge: '/assets/images/badges/digital_badge_bc.png'
        },
        bcvip: {
            badge: '/assets/images/badges/digital_badge_bcvip.png'
        },
        vip: {
            badge: '/assets/images/badges/digital_badge_vip.png'
        },
        gpsvip: {
            badge: '/assets/images/badges/digital_badge_vip.png'
        }
    };
   
    const getmemberInfo = () => {
        const aftermem = (mInfo) => {
            let memberInfo = decodeToken(mInfo.member);
            setProfile(memberInfo);
            setProfileReceived(true)
        }
        let url = variables.buProcessMemberService + '/api/members/getportalmember';
        rests.processrest (url, aftermem, 'post', {membernumber: id});
    }

    useEffect(() => {
        getmemberInfo();
    }, [])
    
    return (
        <>
            {profilereceived ? <NetworkingPage wm={wm} badges={badges} {...props} /> : <></>}
        </>
    )
}
export default ViewProfile;