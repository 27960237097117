import React from "react";
import { Portal } from 'react-portal';
import { IoMdClose } from "react-icons/io"; 
import { FaRegUser } from "react-icons/fa";
import PropTypes from 'prop-types';

const TeamMember = (props) => {
   
    return (
        <div className="flex flex-col items-center pb-10">
                                        
            <div className="relative p-4 rounded-full w-128 h-128">
                {props.tm.teamphoto ? <img className="w-24 h-24 rounded-circle" src={props.tm.teamphoto} alt={'GPS Team Member - ' + props.tm.teamname}/> : <div className=""><FaRegUser className="w-24 h-24 rounded-circle text-white bg-ltgray"/></div>}
            </div>
            <h5 className="mb-1 text-xl font-medium text-tprimary">{props.tm.teamname}</h5>
            <p className="mt-1 text-ltgray text-sm leading-6 ">{props.tm.teamtitle}</p>
            <input type="submit" value="View Bio" className="bg-bgblue hover:bg-bgbluehover text-left text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={(e)=> props.viewteambio(e, props.tm, props)} />
        </div>
    )
};

const BioView = (props) => {
       let wm = props.workingteammember;
       let wmbio = wm.teambio.replace(/<p>/g, '<p className="text-tsecondary leading-8 text-justify" style="line-height:25px;">');
        return(
        <>
            <div id="bio-container-content" className="modal" style={{display: 'block'}}>
                <div className="modalbg fixed w-full bg-modalbg h-100" onClick={(e) => props.closeView()}></div>
                <div id="bio-container" className="modal-content opacity-1 mt-[8%] ml-auto mr-auto max-w-[50%] p-20 bg-white h-auto">
                    <div className="modalclose relative text-right w-full flex justify-end" >
                        <IoMdClose size={24} className="cursor-pointer" onClick={(e) => props.closeView()}/>
                    </div>
                    <h3 className="text-tprimary font-bold">GPS Member Biography</h3>
                    <h4>{wm.teamname}</h4>
                    <div className="text-tsecondary leading-8 text-justify mt-10" dangerouslySetInnerHTML={
                        { __html: wmbio }}>
                            
                    </div>
                </div>
            </div>
        </>
    )
}

/**
 * GPS Member Team Section Component.
 */
class GPSTeam extends React.Component {
    static propTypes = {
		/**
		 * List of team members retrieved from REST call.
		 */
		teamItems: PropTypes.array.isRequired,
        /**
		 * Team member object that has been selected
		 */
        workingteammember: PropTypes.object,
        /**
		 * Button function to view team member bio
		 */
        viewteambio: PropTypes.func,
        /**
		 * Button function that closes team member bio
		 */
        closeView: PropTypes.func
	};

    static defaultProps = {
        teamItems: [],
        workingteammember: {},
        viewteambio: (e, teamInfo, props) => {},
        closeView: () => {},
    }
    constructor(props) {
        super(props);
        this.state = {bioview: false, workingteammember: {}};
        this.viewteambio = this.viewteambio.bind(this)
        this.closeView = this.closeView.bind(this)
       
        this.teamItems = this.props.teammembers;

        let addprops = {
            viewteambio: this.viewteambio,
            closeView: this.closeView,
            workingteammember: this.state.workingteammember
        }

        this.allprops = {...props, ...addprops}
    }
    viewteambio (e, teamInfo, props) {
        this.allprops.workingteammember = teamInfo;
        this.setState({workingteammember: teamInfo})
        this.setState({bioview: true} );
    }

    closeView() {
        this.setState({bioview: false} );
    }

    render() {

        return (
            <>
                <section className="bg-white">
                    <div className="grid grid-cols-12 gap-5 ">
                        <div className="col-span-12 pt-20">
                            <div id="gps-team-container" className="container">
                                <h1 className="text-tprimary font-bold">Meet The GPS Team</h1>
                                <div className="flex justify-center max-w-lg flex-col mx-auto">
                                    <p className="text-tsecondary leading-6 text-center">
                                        Our team is here to help in any way possible
                                    </p>
                                </div>
                                
                                <div className={`grid gap-4 ${this.props.isMobile ? 'grid-cols-1' : 'grid-cols-3'}`}>
                                    
                                    {this.props.teammembers && this.props.teammembers.length ? this.props.teammembers.map((v) => {
                                        return (
                                            <div key={Math.random()} className="w-full max-w-full  bg-white ">
                                                <TeamMember {...this.allprops} tm={v}/>
                                            </div>
                                        )
                                    }) : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.bioview ? <Portal><BioView {...this.allprops}/></Portal>: <></>}
                </section>
        
            </>
        )
    }
}

export default GPSTeam;