import React from "react";

// import { Carousel } from 'react-responsive-carousel';
import goodSoil from "../../assets/images/goodsoil_banner.jpg";
import baackground from "../../assets/images/background17.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css";


const bannerData = [
  {
    id: 1,
    title: "",
    src: "http://gpspartner.org/wp-content/uploads/2024/07/RKS15399-scaled.jpg"
  },
  {
    id: 2,
    title: "",
    src: "http://gpspartner.org/wp-content/uploads/2024/06/Phill2-48-scaled.jpg"
  },
  {
    id: 3,
    title: "",
    src: "http://gpspartner.org/wp-content/uploads/2024/07/0Q0A7859-scaled.jpg"
    // src: "http://gpspartner.org/wp-content/uploads/2024/06/Phill2-48-scaled.jpg"
  },
  {
    id: 4,
    title: "",
    src: "http://gpspartner.org/wp-content/uploads/2024/07/RKS15400-scaled.jpg"
  },
]

const Welcome = (props) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const gotoHref = (h) => {
    var top = document.getElementById(h).offsetTop; //Getting Y of target element
    window.scrollTo(0, top);  
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // partialVisibilityGutter: 10 ,
      // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <>
      <div style={{ marginTop: 130, position: "relative" }}>
        <Carousel
          // centerMode={true}
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          // keyBoardControl={true}
          customTransition="transform 500ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          //   deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          style={{ borderRadius: 0 }}
          // itemClass="carousel-item-padding-40-px"
        >
          {bannerData.map((item, index) => {
            return (
              <img
              key={index}
              className="bannerImg"
            id="carasel"
            src={item.src}
          />
            )
          })}
          {/* <img
            id="carasel"
            src={"http://gpspartner.org/wp-content/uploads/2012/04/1.jpg"}
            style={{
              width: "100vw",
              height: "90vh",
              objectFit: "cover",
              opacity: 0.5,
              borderRadius: 0,
            }}
          />
          <img
            id="carasel"
            src={"http://gpspartner.org/wp-content/uploads/2012/04/013.jpg"}
            style={{
              width: "100vw",
              height: "90vh",
              opacity: 0.5,
              objectFit: "cover",
              borderRadius: "none",
            }}
          />
          <img
            id="carasel"
            src={"http://gpspartner.org/wp-content/uploads/2012/04/2.jpg"}
            style={{
              width: "100vw",
              height: "90vh",
              opacity: 0.5,
              objectFit: "cover",
              borderRadius: "none",
            }}
          />
          <img
            id="carasel"
            src={"http://gpspartner.org/wp-content/uploads/2012/04/014.jpg"}
            style={{
              width: "100vw",
              height: "90vh",
              opacity: 0.5,
              objectFit: "cover",
              borderRadius: "none",
            }}
          /> */}
        </Carousel>
        <div
          className="welcome-container"
        >
          <div className="container">
            <h1 className="text-tprimary font-bold">
              Welcome to your GPS Member Portal!
            </h1>
          </div>
          <div
            id="text-container"
            className="flex justify-center max-w-lg flex-col mx-auto"
          >
            <p className="text-tprimary leading-6 text-justify">
              Here, you will find exclusive content, resources, and
              opportunities designed just for you. As an active partner, you
              have access to a vibrant community where you can connect, learn,
              and grow. Explore our latest updates, engage with fellow partners,
              and take advantage of all the benefits that come with being a part
              of GPS. Your journey toward engagement and connection starts here!
            </p>
          </div>

          <div className="flex items-center justify-center space-x-4">
            <input
              type="submit"
              value="Upgrade My Membership"
              className="bg-bgblue hover:bg-bgbluehover text-left text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={() =>
                openInNewTab(
                  "https://us.mobileaxept.com/ims/mobileaxept/donate.jsp?&org_code=TDJ008&sgr=119&utm_medium=online"
                )
              }
            />

            <button
              className="bg-transparent border-solid border-1 border-tprimary hover:bg-bgbluehover text-left text-tprimary font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={() =>
                gotoHref(
                  "partnerNewsContainer"
                )
              }
            >
              Partner Newsletter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
