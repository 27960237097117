import React from "react";
//import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import PropTypes from 'prop-types';
import BlogItems from "./BlogItems";
import Connect from "../landingpage/Connect";


/**
 * GPS Member Blog List Component.
 */
class BlogList extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {activeIndex: 0, currID: 1, topic: 'outreach'}
        this.onTabClick = this.onTabClick.bind(this);
        
    }

    onTabClick (tab, idx) {
        this.setState({activeIndex: idx})
        let topicslug = tab.slug;
        if (this.props.bloglist[topicslug]) {
            this.setState({topic: topicslug})
        } else {
            this.setState({topic: 'outreach'})
        }
    }
    render() {
        return (
            <>
                <section className="bg-white mt-[7%] pb-30 mb-[7%]">
                    <div className="container">
                        <div className="col-span-12 pt-20 pb-0">
                            <div className="text-center">
                                <h3 className="text-tprimary font-bold">GPS Member Blog</h3>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-10">
                                <div className="relative mb-2">
                                    <svg className="absolute left-2 top-4 block h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <circle cx="11" cy="11" r="8" className=""></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65" className=""></line>
                                    </svg>
                                    <input type="text" id="keyword" name="keyword" className="block rounded pr-40 pl-10 pb-2.5 py-3 w-full text-sm text-gray-900 border border-b-2 border-gray-300 appearance-none border-gray-600 focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer" placeholder="Search" defaultValue="" />
                                </div>
                                <div id="donation-container2" className="flex flex-row mb-[40px] gap-[40px]">
                                    {this.props.blogtabs && this.props.blogtabs.length ? this.props.blogtabs.map((item, index) => {
                                        return (
                                        <div id="tabs-container-profile" className={`${index === this.state.activeIndex ? 'bg-bgblue' : 'bg-bgltblue'} cursor-pointer border-bgblue border-0 border-solid p-[10px] flex min-w-2 xl:h- justify-center items-center px-[20px] rounded-[30px]`}
                                            onClick={() => this.onTabClick(item, index)}
                                            key={index}
                                        >
                                            <h6  className={`self-center ${index === this.state.activeIndex ? "text-white" : "text-bgblue"} mt-1`}>
                                            {item.cat}
                                            </h6>
                                        </div>
                                        );
                                    }) : <></>}
                                </div>
                            </div>
                        </div>
                        <BlogItems {...this.props} topic={this.state.topic}/>
                    </div>
                </section>
                <Connect {...this.props} bgblue={true}/>
            </>
        )
    }
}

export default BlogList;
