import React, { useState, useEffect, useRef } from "react";
//import ChatBar from "../molecule/ChatBar";
import ChatBody from "../molecule/ChatBody";
import ChatFooter from "../molecule/ChatFooter";
//import { io } from "socket.io-client";
import ChatProfileImage from "../atoms/ChatProfileImage";

// import { ChatEngine } from 'react-chat-engine';

const Chat = ({ socket, userName, user }) => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  //const [privates, setPrivate] = useState([]);
  const [myChats, setMYChats] = useState(["GPS Members"])
  const [chat, setChat] = useState([]);
  const [room, setRoom ] = useState("");
  const [activeChat, setActiveChat] = useState("GPS Members");
 
  const [sendTo, setSendTo] = useState();
  const lastMessageRef = useRef(null);

  useEffect(() => {
    console.log("userrrrr",user)
    socket.on("connect", () => {
      console.log("***Connected to Chat Server***");
      socket.emit("newUser", { ...user });
      localStorage.setItem("userName", userName);
    });
    /*
    socket.on("connect_error", (error) => {
      if (socket.active) {
        console.log("checking if socket is active");
        console.log("Socket connected: "+socket.connected);
        // temporary failure, the socket will automatically try to reconnect
      } else {
        // the connection was denied by the server
        // in that case, `socket.connect()` must be manually called in order to reconnect
        console.log("socket connection failed")
        console.log(error.message);
      }
    });
    */

    socket.on("disconnect", () => {
      socket.emit("disconnect", { ...user });
      console.log("***Disconnected from server***");
    });
  }, []);
  // useEffect(() => {

  // },[])

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages([...messages, message]);
    });
  }, [messages]);

  useEffect(() => {
    socket.on("newUserResponse", (data) => {
      console.log("new user response ----> ", data);
    //   const self = data.forEach((user) => {
    //     return {
    //       text: user.text,
    //       name: user.name,
    //       id: user.id,
    //       time: user.time,
    //       socketID: user.socket.id,
    //       self: user.socketID === data.id ? true : false
    //     };
    //   });
    //   const filterData = self?.sort((a, b) => {
    //     if (a.self) return -1;
    //     if (b.self) return 1;
    //     if (a.userName < b.userName) return -1;
    //     return a.userName > b.userName ? 1 : 0;
    //   });
      setOnlineUsers(data)
    });
  }, [onlineUsers]);

  useEffect(() => {
    socket.on("private message", (data) => {
        console.log("private message ----> ", data);
        // const messageFrom = onlineUsers.find((item) => { return item.socketID === data.from ? item.userName : null})
            

            setActiveChat(data.data.name);
            //const newArray = [];
            const chatExist = myChats.forEach((item) => {
              
            })
            console.log("ruffff",myChats, chatExist)
            if (chatExist) {
              setMYChats([...myChats])
            } else {
              setMYChats([...myChats, data.data.name])
            }

        setMessages([...messages, data.data]);
    })

    socket.on("chat-message", (data) => {
      console.log("chatttttt",data)
      setChat([...chat, {...data}]);

    })
  }, [messages, chat])

  //   const [typingStatus, setTypingStatus] = useState("");
  //   const [messages, setMessages] = useState([
  //     {
  //       id: "rUaebWelTLNbH33wAACL0.8408582839533811",
  //       name: "daddy",
  //       socketID: "rUaebWelTLNbH33wAACL",
  //       text: "Hey",
  //       time: "11:56:19",
  //     },
  //     {
  //       id: "rUaebWelTLNbH33wAACL0.8408582839533811",
  //       name: "daddy",
  //       socketID: "rUaebWelTLNbH33wAACL",
  //       text: "Hey",
  //       time: "11:56:19",
  //     },
  //     {
  //       id: "CyhY_C7F4LntlGV7AAC30.18785246231000086",
  //       name:"landon",
  //       socketID: "CyhY_C7F4LntlGV7AAC3",
  //       text: "meowwww what sup ",
  //       time: "11:56:19",
  //     },
  //     {
  //       id: "8d9FAJO2FQOkaZhjAACR0.28666937299969675",
  //       name: "Landon",
  //       socketID: "8d9FAJO2FQOkaZhjAACR",
  //       text: "Hey",
  //       time: "12:1:40",
  //     },
  //   ]);
  //   // const [userName, setUserName] = useState("LAndon");
  //   const [onlineUsers, setOnlineUsers] = useState([]);
  //   const lastMessageRef = useRef(null);
  //   const [rooms, setRooms] = useState(["All Members"]);
  //   const [currentRoom, setCurrentRoom] = useState();
  //   // const URL =
  //   //   process.env.NODE_ENV === "production" ? undefined : "http://localhost:4000";
  //   // const socket = io(URL, {
  //   //   autoConnect: false,
  //   // });

  //   useEffect(() => {
  //     console.log("runnnnnnx")
  //     // 👇️ scroll to bottom every time messages change
  //     lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  //     console.log('daddy',lastMessageRef?.current)
  //   }, [messages]);

  //   // useEffect(() => {
  //   //   // socket.on('typingResponse', (data) => {
  //   //   //   setTypingStatus(data);
  //   //   //   console.log(data);
  //   //   });
  //   // }, [socket]);

  //   useEffect(() => {
  //     socket.on("messageResponse", (data) => {
  //       setMessages((currentMessages) => [...currentMessages, data]);
  //     });
  //     socket.on("newUserResponse", (data) => {
  //       console.log("data", data);
  //       const users = data.sort((a, b) => {
  //         if (a.self) return -1;
  //         if (b.self) return 1;
  //         if (a.username < b.username) return -1;
  //         return a.username > b.username ? 1 : 0;
  //       });
  //       setOnlineUsers(users);
  //     });
  //     socket.on("user connected", (user) => {
  //       console.log('meoww--->', user)
  //     });
  //   }, []);

//   useEffect(() => {
//     if (activeChat != "GPS Members"){
//         setSendTo
//     }
//   },[activeChat])

    const onDirectMessage = (content) => {
      setRoom(content);
      setMYChats([...myChats, content.username])
      //const myCurrentChats = myChats
  
      console.log("content", myChats);
      if (content.username === user.username) {
        console.log('Cant message myself!')
      } else {
        setActiveChat(content.username);
        setMYChats([...myChats, content.username])
        setSendTo(content);
        setMessages([]);
      }
    };

  // messages needs to be sent to an api to hold data f past messages
  console.log("In Chat ----> ", myChats);
  
  return (
    // <div style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    // {allUsers.map((item) => {
    //     return (
    //         <h1>{item.userName}</h1>
    //     )
    // })}
    // </div>
    <section style={{ maxHeight: "100vh", overFlowX: "hidden" }}>
      <div
        style={{
          height: "80vh",
          marginTop: 150,
          display: "flex",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "25%",
            borderColor: "black",
            borderWidth: 1,
            borderStyle: "solid",
            height: "80vh",
          }}
        >
          <h2>Active Users</h2>
          {onlineUsers?.map((item, index) => {
            if (item?.userId !== user?.userId) {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => onDirectMessage(item)}
                >
                  <ChatProfileImage name={item.username} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    <p style={{ textAlign: "center", marginTop: 10 }}>
                      {item.username ? item.username.toUpperCase() : ''}
                    </p>
                  </div>
                </div>
              );
            }
            
          })}
        </div>
        
        <ChatBody chat={chat}  user={user} room={room} messages={messages} lastMessageRef={lastMessageRef} activeChat={activeChat}/>
        <ChatFooter room={room} user={user} socket={socket} activeChat={activeChat} onDirectMessage={onDirectMessage} selectedUser={sendTo} setSelectedUser={setSendTo} userName={userName}/>
        
        <div
          style={{
            borderColor: "green",
            borderStyle: "solid",
            borderWidth: 1,
            width: "15%",
          }}
        >
          <div
            style={{
              borderBottomColor: "grey",
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
            }}
          >
            <h1>Rooms</h1>
          </div>
          {myChats?.map((item, index) => {
            return (
              <div style={activeChat === item ? {backgroundColor: 'grey', minHeight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'} : {backgroundColor: 'white', minHeight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <h4>{item}</h4>
              </div>
            )
          })}
        </div>
      </div>

    </section>
  );
};

export default Chat;


