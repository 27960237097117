import React, { useState } from 'react';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

const PhotoCarasel = (props) => {
  const [current, setCurrent] = useState(props.index);

  const nextClick = () => {
    setCurrent(current === props.slides.length - 1 ? 0 : current + 1);
  };

  const prevClick = () => {
    setCurrent(current === 0 ? props.slides.length - 1 : current - 1);
  };

  return (
    <div className="modalContainer">
      <p className="close" onClick={() => props.closeClick()}>
        X
      </p>

      <AiOutlineLeft id="iconLeft" onClick={prevClick} />

      <div className="innerContainer">
        {props.slides.map((item, index) => {
          return (
            <div
              className={index === current ? 'slide active' : 'slide'}
              key={index}
            >
              {index === current && (
                <img className="imageSlider" src={item.url} alt="logo" />
              )}
            </div>
          );
        })}
      </div>

      <AiOutlineRight id="iconRight" onClick={nextClick} />
    </div>
  );
};

export default PhotoCarasel;
