import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/index.css';
import './assets/web/assets/mobirise-icons2/mobirise2.css';
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/bootstrap/css/bootstrap-grid.min.css';
import './assets/bootstrap/css/bootstrap-reboot.min.css';
import './assets/parallax/jarallax.css';
import './assets/dropdown/css/style.css';
import './assets/socicon/css/styles.css';
import './assets/theme/css/style.css';
import './assets/mobirise/css/mbr-additional.css?v=prEY0A';
import './output.css';
import './styles/custom.css';

/*import './assets/bootstrap/js/bootstrap.bundle.min.js';
//import './assets/parallax/jarallax.js';
import './assets/smoothscroll/smooth-scroll.js';
//import '../assets/ytplayer/index.js';
//import './assets/dropdown/js/navbar-dropdown.js';
//import './assets/scrollgallery/scroll-gallery.js';
import './assets/mbr-switch-arrow/mbr-switch-arrow.js';
//import '../assets/masonry/masonry.pkgd.min.js';
//import '../assets/imagesloaded/imagesloaded.pkgd.min.js';
//import './assets/theme/js/script.js';
//import './assets/formoid/formoid.min.js';*/

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <App />
  
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
