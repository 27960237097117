import React from "react";
import Rests from "../rests/services";
import PropTypes from 'prop-types';
const rests = new Rests();

/**
 * Shared functions that can be used throughout the project.
 */
class SharedFunctions extends React.Component {

    static propTypes = {
		/**
		 * shared function to get member information from rest call
		 */
        getmember: PropTypes.func,
        /**
		 * Calculates page data and pages when clicking next and previous on transaction list
		 */
        CalcPage: PropTypes.func,
        /**
		 * Reformats numbers with commas to display of currency
		 */
        numberWithCommas: PropTypes.func,

        openInNewTab: PropTypes.func
	};

    static defaultProps = {
        getmember: (props) => {

        },
        CalcPage: (pg, props, filtered, filterlist) => {

        },
        numberWithCommas: (x) => {

        },
        openInNewTab: (url) => {

        }
    }

    CalcPage = (pg, props, filtered, filterlist) => {
        let pageItems = filtered && filterlist  ? filterlist : props.items;
        
        let page = pg || 1;
        let per_page = props.per_page || 20;
        let totalitems = pageItems ? pageItems.length : 0;
        const offset = (page - 1) * per_page;
        
        const paginatedItems = pageItems ? pageItems.slice(offset).slice(0, per_page) : [];
        const total_pages = Math.ceil(totalitems / per_page);
        
        let pageInfo = {
            page: page,
            per_page: per_page,
            pre_page: (page+1) - 2 ? (page+1) - 2 : null,
            next_page: (total_pages > page) ? page + 1 : null,
            total: totalitems ? totalitems : (pageItems ? pageItems.length : 0),
            total_pages: total_pages,
            data: paginatedItems
        }

       
        
        return pageInfo;
    }

openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      };

    getmember = (props) => {
        return new Promise(resolve => {
            let cookieuserInfo = JSON.parse(JSON.stringify(props.cookies.gpsuser));
            if (cookieuserInfo.p) {
                
                const aftergetmember = (mObj) => {
                    props.setuserinfo(mObj.memberdonorInfo);
                    props.setItems(mObj.memberdonorInfo.transactions);
                    props.setworkingmember(mObj.workingmember);
                    
                    setTimeout(() => {
                        let pageInfo = this.CalcPage(1, props);
                        props.setListItems(pageInfo.data);
                        resolve(mObj);
                    }, 3000);
                }
                rests.getmember (cookieuserInfo.u, cookieuserInfo.p, aftergetmember);
                
            } else {
                console.log('information not set in cookie');
            }
        });
    }

    numberWithCommas(x) {
        var parts = x.toString().split(".");
        return parts[0].replace(/\B(?=(\d{3})+(?=$))/g, ",") + (parts[1] ? "." + parts[1] : "");
    }

    
}

export default SharedFunctions;