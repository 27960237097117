import React from "react";
import axios from 'axios';
import variables from "./variables";
import { enc, HmacSHA256 } from 'crypto-js';
//import sha256 from 'crypto-js/sha256';
import { decodeToken } from "react-jwt";
class Rests extends React.Component {
  
    approvemember = (loginInfo) => {
      let approved = false;
      let approvedcodes = ['GPSVIP','BCVIP','BC','AA'];
      if (loginInfo && parseInt(loginInfo.donoraccount)) {
        if (approvedcodes.includes(loginInfo.partner)) {
          approved = true;
        }
      }
      return approved;
    }

    getmember = (username, password, func) => {
      let outcome = {memberdonorInfo: {}, workingmember: {}}
      const aftermember = (memInfo) => {
                  
          let mdi = decodeToken(memInfo);
          
          const openmember = () => {
            const aftergps = (gpsInfo) => {
              
              let gpsMemInfo = {};
              let ssms = [{
                  type: '',
                  link: ''
              }];
              if (gpsInfo && gpsInfo.member) {
                  gpsMemInfo = decodeToken(gpsInfo.member);
                  
                  if (gpsMemInfo.ssms) {
                      ssms = JSON.parse(gpsMemInfo.ssms);
                  }
              }
              
              let mInfo = {
                  donoraccount: mdi.donoraccount,
                  firstname: mdi.firstName,
                  lastname: mdi.lastName,
                  email: mdi.email,
                  title: gpsMemInfo.title ? gpsMemInfo.title : '',
                  company: gpsMemInfo.company ? gpsMemInfo.company : '',
                  partner: mdi.partner,
                  partnerdesc: mdi.partnerdesc,
                  address1: mdi.address && mdi.address.line1 ? mdi.address.line1 : '',
                  address2: mdi.address && mdi.address.line2 ? mdi.address.line2 : '',
                  city: mdi.address && mdi.address.city ? mdi.address.city : '',
                  state: mdi.address && mdi.address.state ? mdi.address.state : '',
                  zip: mdi.address && mdi.address.postalCode ? mdi.address.postalCode : '',
                  country: mdi.address && mdi.address.country ? mdi.address.country : '',
                  phone: mdi.phone && mdi.phone.phoneNumber ? (mdi.phone.areaCode ? '(' + mdi.phone.areaCode + ') ' + mdi.phone.phoneNumber : mdi.phone.phoneNumber) : '',
                  bio: gpsMemInfo.bio ? gpsMemInfo.bio : '',
                  ssms: ssms,
                  transactions: mdi.transactions,
                  photo: gpsMemInfo.photo ? gpsMemInfo.photo : '',
                  addressID: mdi.address && mdi.address.id ? mdi.address.id : 0,
                  phoneID: mdi.phone && mdi.phone.id ? mdi.phone.id : 0,
                  emailID: mdi.emailInfo && mdi.emailInfo.id ? mdi.emailInfo.id : 0,
                  showprofile: gpsMemInfo.showprofile ? gpsMemInfo.showprofile : 0,
                  showemail: gpsMemInfo.showemail ? gpsMemInfo.showemail : 0,
                  showphone: gpsMemInfo.showphone ? gpsMemInfo.showphone : 0,
                  showtitle: gpsMemInfo.showtitle ? gpsMemInfo.showtitle : 0,
                  showcompany: gpsMemInfo.showcompany ? gpsMemInfo.showcompany : 0,
                  showsocialmedia: gpsMemInfo.showsocialmedia ? gpsMemInfo.showsocialmedia : 0,
                  openconnection: gpsMemInfo.openconnection ? gpsMemInfo.openconnection :  0,
                  giftorders: gpsMemInfo.giftorders ? gpsMemInfo.giftorders : [],
                  photoapproved: gpsMemInfo.photoapproved ? gpsMemInfo.photoapproved : 0
              }

              outcome.memberdonorInfo = mdi;
              outcome.workingmember = mInfo;

              if (func) {
                func(outcome);
              }
            }

            let memInfo = {
                membernumber: mdi.donoraccount
            }
            let gpsurl = variables.buProcessMemberService + '/api/members/getmemberprofile';
            
            this.processrest (gpsurl, aftergps, 'post', memInfo);
          }
          if (this.approvemember(mdi)) {
              openmember();
              
          } else {
              
              

              let filtertype = mdi.typeInfo && mdi.typeInfo.length && mdi.typeInfo[0].items.length ? mdi.typeInfo[0].items.filter(t=> t.type === 'GPSLEVEL') : [];
              if (filtertype.length) {
                  
                  mdi.partner = filtertype[0].value;
                  mdi.partnerdesc = filtertype[0].valueDescription;
                  if (this.approvemember(mdi)) {
                    openmember();
                  } else {
                    
                    if (this.props) {
                      this.props.setcookie('gpsuser', '', { path: '/' });
                      this.props.setlogin(false);
                    }

                    outcome.memberdonorInfo = {};
                    outcome.workingmember = {};
      
                    if (func) {
                      func(outcome);
                    }
                    
                  }
                 
              } else {
                 
                  if (this.props) {
                    this.props.setcookie('gpsuser', '', { path: '/' });
                    this.props.setlogin(false);
                  }

                  outcome.memberdonorInfo = {};
                  outcome.workingmember = {};
    
                  if (func) {
                    func(outcome);
                  }
              }
          }
      }
      this.retrievemember(username,password,aftermember);
    }


    retrievemember = (username, password, func) => {
      var searchData = {
        customeremail: username ,
        email: username,
        firstName: '',
        lastName: '',
        zipCode: "",
        conferenceID: 0,
        donoraccount: password,
        partner: "NA",
        partnerdesc: "",
        gender: "",
        age: "",
        address: [],
        phone: [],
        membershipInfo: [],
        typeInfo: [],
        emailInfo: [],
        donortoken: "",
        levels: "GSPVIP,BCVIP,BC,AA",
        trybyemail: 1,
        gettransactions: 1,
        transactions: []
      };
      
      let url = variables.buProcessService + '/api/searchdonor';
      const jwtSecretKey = variables.jwtscr;
      const token = this.createjwt(searchData, jwtSecretKey);
      let searchInfo = {
          searchjwt: token
      }
      
      this.processrest (url, func, 'post', searchInfo);
    }

    processrest = (url, func, method, postData) => {
        let bearerKey = this.decode(variables.bk);
        let axiosConfig = {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Authorization": 'Bearer ' + bearerKey,
          }
        };
        if (method === 'get') {
         
          axios[method](url, axiosConfig)
          .then((response) => {
            
            if (func) {
              func(response.data);
            }
            
            
          })
          .catch((error) => {
            console.log(error)
          })
        } else {
          axios[method](url, postData, axiosConfig)
          .then((response) => {
            
            if (func) {
              func(response.data);
            }
            
            
          })
          .catch((error) => {
            console.log(error)
          })
        }
    }

    base64url(source) {
      // Encode in classical base64
      var encodedSource = enc.Base64.stringify(source);
      
      // Remove padding equal characters
      encodedSource = encodedSource.replace(/=+$/, '');
      
      // Replace characters according to base64url specifications
      encodedSource = encodedSource.replace(/\+/g, '-');
      encodedSource = encodedSource.replace(/\//g, '_');
      
      return encodedSource;
    }

    createjwt (data, secret ) {
		
      var header = {
        "alg": "HS256",
        "typ": "JWT"
      };
      
      var stringifiedHeader = enc.Utf8.parse(JSON.stringify(header));
      var encodedHeader = this.base64url(stringifiedHeader);
      
      var stringifiedData = enc.Utf8.parse(JSON.stringify(data));
      var encodedData = this.base64url(stringifiedData);
      
      var token = encodedHeader + "." + encodedData;
      var signature = HmacSHA256(token, secret);
      signature = this.base64url(signature);
      
      var signedToken = token + "." + signature;
      return signedToken;
    }

    encode(str) {
      let output = "";
      let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      let i = 0;
      const _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
      const input = this.utf8_encode(str);

      while (i < input.length) {
          chr1 = input.charCodeAt(i++);
          chr2 = input.charCodeAt(i++);
          chr3 = input.charCodeAt(i++);
          enc1 = chr1 >> 2;
          enc2 = (chr1 & 3) << 4 || chr2 >> 4;
          enc3 = (chr2 & 15) << 2 || chr3 >> 6;
          enc4 = chr3 & 63;

          if (isNaN(chr2)) {
              enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
              enc4 = 64;
          }

          output = output + _keyStr.charAt(enc1) + _keyStr.charAt(enc2) + _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
      }
      return output;
    }

    decode (input) {
      const _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
      let output = "";
      let chr1, chr2, chr3;
      let enc1, enc2, enc3, enc4;
      let i = 0;
    
      input = input.replace(/[^A-Za-z0-9+/=]/g, "");
    
      while (i < input.length) {
    
          enc1 = _keyStr.indexOf(input.charAt(i++));
          enc2 = _keyStr.indexOf(input.charAt(i++));
          enc3 = _keyStr.indexOf(input.charAt(i++));
          enc4 = _keyStr.indexOf(input.charAt(i++));
    
          chr1 = (enc1 << 2) | (enc2 >> 4);
          chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
          chr3 = ((enc3 & 3) << 6) | enc4;
    
          output = output + String.fromCharCode(chr1);
    
          if (enc3 !== 64) {
              output = output + String.fromCharCode(chr2);
          }
          if (enc4 !== 64) {
              output = output + String.fromCharCode(chr3);
          }
    
      }
    
      output = this.utf8_decode(output);
    
      return output;
    }

    utf8_encode(str) {
      const string = str.replace(/\r\n/g, "\n");
      let utftext = "";

      for (let n = 0; n < string.length; n++) {
          const c = string.charCodeAt(n);

          if (c < 128) {
              utftext += String.fromCharCode(c);
          } else if (c > 127 && c < 2048) {
              utftext += String.fromCharCode(c >> 6 || 192);
              utftext += String.fromCharCode(c & 63 || 128);
          } else {
              utftext += String.fromCharCode(c >> 12 || 224);
              utftext += String.fromCharCode(c >> 6 & 63 | 128);
              utftext += String.fromCharCode(c & 63 | 128);
          }
      }

      return utftext;
    }

    utf8_decode(utftext) {
      let string = "";
      let i = 0;
      let c = 0;
      let c3 = 0;
      let c2 = 0;
  
      while ( i < utftext.length ) {
  
          c = utftext.charCodeAt(i);
  
          if (c < 128) {
              string += String.fromCharCode(c);
              i++;
          }
          else if((c > 191) && (c < 224)) {
              c2 = utftext.charCodeAt(i+1);
              string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
              i += 2;
          }
          else {
              c2 = utftext.charCodeAt(i+1);
              c3 = utftext.charCodeAt(i+2);
              string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
              i += 3;
          }
  
      }
  
      return string;
    }
}



export default Rests;