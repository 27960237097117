import React from "react";

/**
 * GPS Member Chat Profile Image.
 */
const ChatProfileImage = (name) => {
    // console.log('meowww', name)
  return (
    <div
    style={{
        backgroundColor: "red",
        width: 40,
        display: "flex",
        height: 40,
        borderRadius: 20,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{marginTop: 17, fontSize: 26  }}>
     {name.name && name.name[0] ? name.name[0].toUpperCase() : ''}
      </p>
    </div>
  );
};

export default ChatProfileImage;
