import React, { useState, useEffect } from "react";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
//import axios from "axios";

import Rests from "../rests/services";

const Connect = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const rest = new Rests();
  const [messageSent, setMessageSent] = useState(false);
  let bg = props.bgblue ? true : false;
  const [bgblue] = useState(bg);
  
  let contactInfo = props.contactInfo && props.contactInfo[0] ? props.contactInfo[0].contactinfo.replace(/<p>/g, '<p className="text-tsecondary leading-8 text-justify" style="line-height:25px;">') : '';
  

  useEffect(() => {}, [name, email, phoneNumber, message]);
  const handleChange = (event) => {
    if (event.target.name === "first-name") {
      setName(event.target.value);
    }

    if (event.target.name === "email") {
      setEmail(event.target.value);
    }

    if (event.target.name === "phone-number") {
      setPhoneNumber(event.target.value);
    }

    if (event.target.name === "message") {
      setMessage(event.target.value);
    }
  };

  

							
						

  const onSubmit = () => {
    let emailbody = `<table style="width: 100%;" width="100%" >
									<tbody>
									<tr>
									<td style="padding: 0 62px 10px; text-align: center;"><a href="https://www.gpspartner.org"> <img title="GPS Member Portal" src="https://gpspartner.org/wp-content/uploads/sites/6/2017/09/gpslogo.png" alt="GPS Member Portal" width="180"> </a></td>
									</tr>
									<tr>
									<td className="three-columns">
									<table className="content" width="100%">
									<tbody>
									<tr>
									<td className="padding">
									<h3 style="text-align:center">GPS Portal Prayer Request</h3>
									<p className="p1" >Name: ${name}</p>
									<p className="p1" >Email: ${email}</p>
                  <p className="p1" >Email: ${phoneNumber}</p>
									<p className="p2">Message</p>
									<p className="p1">${message}</p>
									</td>
									</tr>
									</tbody>
									</table>
									</td>
									</tr>
									<tr>
									<td className="padding">&nbsp;</td>
									</tr>
									</tbody>
									</table>`;
    var emailInfo = {
      subject: "GPS Portal Prayer Request",
      template: "template_container",
      emailto: "TDJM_partners@incontactemail.com",
      variables: {
        template: emailbody,
      },
    };
    const afterRest = () => {
      
      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
      setMessageSent(true);
    };

    let url = "https://bvtv.tdjpartners.com/sendwebmail";
    rest.processrest(url, afterRest, "post", emailInfo);
  };

  const openWindow = (url) => {
    window.open(url, 'window_name', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=1280, height=720'); return false;
  }

  return (
    <div
      id="footer-container"
      className={"flex justify-evenly min-h-[220px] pt-[40px] pb-[40px] " + (bgblue ? 'bg-bgblue text-white' : '')}
    >
      <div id="footer-section-container">
        <div>
          <h3
            style={{
              textAlign: "left",
              marginBottom: 30,
              margintop: 30,
              fontWeight: "bold",
            }}
          >
            Contact Us
          </h3>
          <div id="contact" />
        </div>
        <div id="contactarea" className={"text-tsecondary text-left " + (bgblue ? 'text-white' : '')}  dangerouslySetInnerHTML={{ __html: contactInfo }}>
          
        </div>
       
       

        <div style={{ display: "flex", alignItems: "left" }}>
          <div 
          id="social-container"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: 70,
            }}
          >
            <div
              style={{
                borderColor: "rgba(230, 237, 243, 1)",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 20,
                padding: 1,
                height: 40,
                width: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 25,
                marginRight: 15,
              }}
            >
              <a href="https://www.instagram.com/tdjgps/">
                <button style={{ display: "flex", color: "black" }}>
                  <FaInstagram className={"text-tprimary " + (bgblue ? 'text-white' : '')} />
                </button>
              </a>
            </div>
            <div
              style={{
                borderColor: "rgba(230, 237, 243, 1)",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 20,
                padding: 1,
                height: 40,
                width: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 25,
              }}
            >
              <a href="https://www.facebook.com/groups/414229202502720">
                <button style={{ display: "flex", color: "black" }}>
                  <FaFacebookF className={"text-tprimary " + (bgblue ? 'text-white' : '')} />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="footer-section-container">
        <div>
          <h3
            style={{
              textAlign: "left",
              marginBottom: 30,
              margintop: 30,
              fontWeight: "bold",
            }}
          >
            Quick Links
          </h3>
        </div>

        <div style={{ display: "flex", alignItems: "left" }}>
          
            <p className={"text-tprimary cursor-pointer " + (bgblue ? 'text-white' : '')} onClick={(e) => openWindow('https://forms.thepottershouse.org/customer-service?cs=bFRqNlBuVlpycWVkOXRqTUpoY1BEdz09')} target="_blank"> Contact Us</p>
          
        </div>

        <div style={{ display: "flex", alignItems: "left" }}>
          <a href="/#events">
            {" "}
            <p className={"text-tprimary " + (bgblue ? 'text-white' : '')}> Events</p>
          </a>
        </div>

        <div style={{ display: "flex", alignItems: "left" }}>
          <a href="/#gallery">
            {" "}
            <p className={"text-tprimary " + (bgblue ? 'text-white' : '')}> Gallery</p>
          </a>
        </div>

        <div style={{ display: "flex", alignItems: "left" }}>
            <p className={"text-tprimary cursor-pointer " + (bgblue ? 'text-white' : '')} onClick={(e) => openWindow('https://www.gpspartner.org/privacy-policy/')} target="_blank"> Privacy Policy</p>
          
        </div>

        <div style={{ display: "flex", alignItems: "left" }}>
          
            <p className={"text-tprimary cursor-pointer " + (bgblue ? 'text-white' : '')} onClick={(e) => openWindow('https://www.gpspartner.org/terms-of-use/')} target="_blank"> Terms Of Use</p>
          
        </div>
        

        
      </div>
      {!messageSent ? (
        <div >
          <h3 id="prayer"
          className="pl-[20px]"
            style={{
              textAlign: "left",
              marginBottom: 30,
              margintop: 30,
              fontWeight: "bold",
            }}
          >
            Prayer Request
          </h3>

          

          <div
            className="bg-white pt-[20px] pb-[20px]"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              color: "#637381",
            }}
          >
            <div id="name-container" className="bg-white" style={{ display: "flex", color: "#637381" }}>
              <div className="input-wrapper">
                <label htmlFor="first-name">Name</label>

                <input
                  className="name"
                  name="first-name"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  value={name}
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="email">Email</label>
                <input
                  className="email"
                  name="email"
                  onChange={(e) => handleChange(e)}
                  value={email}
                />
              </div>
            </div>
            <div className="input-wrapper">
              <label htmlFor="phone-number">Phone Number</label>

              <input
                className="phone-number"
                name="phone-number"
                onChange={(e) => handleChange(e)}
                value={phoneNumber}
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="message">Message</label>
              <textarea className="input-message"
                name="message"
                onChange={(e) => handleChange(e)}
                value={message}>
              </textarea>
              
            </div>
            <button
              onClick={() => onSubmit()}
              style={{
                backgroundColor: "#1076BC",
                color: "white",
                borderRadius: 6,
                height: 42,
                minWidth: "93%",
                marginTop: 15,
              }}
            >
              Send Request
            </button>
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <h1>Thank You!</h1>
        </div>
      )}
    </div>
  );
};

export default Connect;
