import React, { useState } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import PropTypes from 'prop-types';

/*const facts = [
  {
    id: 0,
    Question: "What are the conference amenities?",
    Answer:
      "Unlock exclusive member content, connect with fellow members on our vibrant social network, explore the member directory, stay updated on exciting events, and shop for unique member products!",
  },
  {
    id: 1,
    Question: "What are the rules about bringing guests to conferences/events?",
    Answer:
      "Becoming a member is as easy as a few clicks! Simply sign up, choose your membership plan, and dive into a world of endless possibilities.",
  },
  {
    id: 2,
    Question: "What is considered an active/lapsed status for partners?",
    Answer:
      "We're not just a membership portal, we're a community of like-minded individuals who thrive on creativity, innovation, and fun! Join us and experience a whole new level of membership benefits!",
  },
  {
    id: 3,
    Question: "What are the different ways that I can give toward partnership?",
    Answer:
      "Our dedicated support team is here to help you every step of the way. Reach out to us for any questions, concerns, or just to say hello!",
  },
];*/

/**
 * GPS Member FAQs Section Component.
 */
const FAQs = (props) => {
// declare variables 
  const [factOpen, setFactOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  // function for opening and closing facts 
  const openFacts = (index, id) => {
    if (activeIndex !== index) {
      setFactOpen(true);
      setActiveIndex(index);
    } else {
      setFactOpen(!factOpen);
      setActiveIndex(index);
    }
  };
  
  // return body 
  return (
    <div className="bg-sunshine flex justify-center flex-col items-center pb-16 pt-10">
      <h1 className="pb-10 pt-10 font-bold text-5xl">FAQ's</h1>
      {props.faqs && props.faqs.length ? props.faqs.map((item, index) => {
        return (
          <div
            id="faqContainer"
            key={index}
            onClick={() => openFacts(index, item.id)}
            className="bg-white w-3/5 rounded-xl p-10 flex-col flex justify-between items-center mt-8 cursor-pointer"
          >
            <div className="flex justify-between items-center w-full">
              <h3 className="text-left ">{item.Question}</h3>
              <div style={factOpen && activeIndex === item.id ? {backgroundColor: 'black'} : {backgroundColor: 'white'}}
                className={`drop-shadow-md rounded-3xl`}
              >
                <MdOutlineKeyboardArrowRight
                  size={50}
                  style={
                    factOpen && activeIndex === item.id
                      ? { transform: "rotate(-90deg)", color: "white" }
                      : null
                  }
                />
              </div>
            </div>
            {factOpen && activeIndex === item.id ? (
              <div>
                <p style={{color: '#637381'}} className="mt-10 text-left pr-16 leading-8" dangerouslySetInnerHTML={
                        { __html: item.Answer.replace(/<p>/g, '<p class="mt-10 text-left pr-16 leading-8">') }}></p>
              </div>
            ) : null}
          </div>
        );
      }) : <></>}
    </div>
  );
};

export default FAQs;
