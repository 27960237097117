import React from "react";
/**
 * GPS Member Impact Section Component. - This section has image of the impact statistics.
 */
const YourImpact = (props) => {
    return (
        <>
            <section className="bg-white">
                <div className="grid grid-cols-12 gap-5 ">
                    <div className="col-span-12 pt-20 pb-20">
                        <div id="impact-container" className="container">
                            <h1 className="text-tprimary font-bold">Your Impact</h1>
                            <div className="flex justify-center max-w-lg flex-col mx-auto">
                                <p className="text-tsecondary leading-6 text-justify">
                                    With the help of our Partners, we seek to further God’s kingdom by making a difference in the lives of His people all over the world. From creating water pipelines to disaster relief, we band together to save the lost, help the hurting, and spread the Gospel in the mighty name of Jesus Christ.
                                </p>
                            </div>
                            <div id="impact-image-container" className="w-100 pt-20">
                                <img className="" src="/assets/images/gps_impact.jpg" alt="GPS Impact"/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default YourImpact;